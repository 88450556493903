import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import {
    AddGroupButton,
    GroupsTable,
} from "../../components";
import { PageStructure } from "..";
import "./Groups.css";

const Groups = (props) => {
  // This hook returns the current location object
  const location = useLocation();

  useEffect(() => {
    // This effect will run whenever the location (URL) changes
 

    // Place your logic here to fetch new data based on the new URL
    // For example, parsing query strings from the URL and fetching data accordingly
  }, [location]); // Depend on location to trigger effect on URL change

  return (
    <PageStructure>
      <h1>Ομάδες</h1>
        <AddGroupButton />
        <GroupsTable />
     
    </PageStructure>
  );
};

export default Groups;
