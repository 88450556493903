import React, { useState, useEffect } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";

import * as XLSX from "xlsx";

import "./ImportReservationButton.css";
import { upload } from "@testing-library/user-event/dist/upload";

const ImportReservationButton = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const BATCH_SIZE = 100;
  const handleFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    setData(jsonData);

    props.setIsLoading && props.setIsLoading(true);

    const uniqueProperties = [
      ...new Set(jsonData.map((reservation) => reservation["Property"])),
    ];

    await Promise.all(
      uniqueProperties.map((property) => uploadProperty(property))
    );

    // Batch upload logic
    for (let i = 0; i < jsonData.length; i += BATCH_SIZE) {
      const batch = jsonData.slice(i, i + BATCH_SIZE);
      await Promise.all(
        batch.map((reservation) => {
          // console.log(mapReservation(reservation));
          return uploadReservation(mapReservation(reservation));
        })
      );
    }

    props.setIsLoading && props.setIsLoading(false);
  };



  const mapReservation = (reservation) => ({
    reservationId: reservation["Reservation ID"],
    property: reservation["Property"],
    checkIn: handleDate(reservation["Checkin"]),
    checkOut: handleDate(reservation["Checkout"]),
    nights: reservation["Nights"]|| handleNumberOfNights(handleDate(reservation["Checkin"]), handleDate(reservation["Checkout"])),
    adults: reservation["Adults"],
    kids: reservation["Kids"] || 0,
    firstName: reservation["First Name"],
    lastName: reservation["Last Name"],
    email: reservation["Email"] || "",
    phone: reservation["Phone"] || "",
    staffNotes: reservation["Staff Notes"] || "",
    arrivalTime: reservation["Arrival Time"] || "",
    arrivalLocation: reservation["Arrival Location"] || "",
    arrivalFlight: reservation["Arrival Flight"] || "",
    departureTime: reservation["Departure Time"] || "",
    departureLocation: reservation["Departure Location"] || "",
    departureFlight: reservation["Departure Flight"] || "",
  });

  const uploadProperty = async (property) => {
    try {
      const response = await axios.post(
        "https://www.maliarakis-server.rhodesislandpass.com/property/createProperty",
        {
          title: property,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {}
  };


  const handleNumberOfNights = (checkIn, checkOut) => {

    
    if (checkIn === undefined || checkOut === undefined) return 0;


    const checkInDate = new Date(
      
      checkIn.split("-")[2],
      checkIn.split("-")[1] - 1,
      checkIn.split("-")[0]

    );
    const checkOutDate = new Date(
      checkOut.split("-")[2],
      checkOut.split("-")[1] - 1,
      checkOut.split("-")[0]
    );



    const diffTime = Math.abs(checkOutDate - checkInDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffDays);
    return diffDays;
  };


  const excelSerialToDate = (serial) =>{
    // Calculate the date from the Excel serial number
    let excelEpoch = new Date(1899, 11, 30); // Excel uses 1900-01-00 as the epoch
    let date = new Date(excelEpoch.getTime() + serial * 24 * 60 * 60 * 1000);

    // Extract the month, day, and year from the date
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();

     // Format the month and day as two digits
     month = month < 10 ? '0' + month : month;
     day = day < 10 ? '0' + day : day;
 
     // Format the year as two digits
     year = year % 100;
     year = year < 10 ? '0' + year : year;

    // Format the year as two digits
    year = year % 100;

    // Return the formatted date string (MM/DD/YY)
    return `${month}-${day}-${year}`;
  }
  const handleDate = (date) => {
    if (typeof date === "number") {
      return excelSerialToDate(date)
        .split("-")
        .map((part, index, array) =>
          index === 0 ? array[1] : index === 1 ? array[0] : part
        )
        .join("-");
    }

    if (date.includes("/")) {
      return date
        .split("/")
        .map((part, index, array) =>
          index === 0 ? array[1] : index === 1 ? array[0] : part
        )
        .join("-");
    } else if (date.includes("-")) {
      return date
        .split("-")
        .map((part, index, array) =>
          index === 0 ? array[1] : index === 1 ? array[0] : part
        )
        .join("-");
    } else {
      return excelSerialToDate(date)
        .split("-")
        .map((part, index, array) =>
          index === 0 ? array[1] : index === 1 ? array[0] : part
        )
        .join("-");
    }
  };

  const uploadReservation = async (reservation) => {
    try {
      const response = await axios.post(
        "https://www.maliarakis-server.rhodesislandpass.com/reservation/createReservation",
        {
          reservationId: reservation.reservationId,
          property: reservation.property,
          checkIn: handleDate(reservation.checkIn),
          checkOut: handleDate(reservation.checkOut),
          nights: reservation.nights,
          adults: reservation.adults,
          kids: reservation.kids,
          firstName: reservation.firstName,
          lastName: reservation.lastName,
          email: reservation.email,
          phone: reservation.phone,
          staffNotes: reservation.staffNotes,
          arrivalTime: reservation.arrivalTime,
          arrivalLocation: reservation.arrivalLocation,
          arrivalFlight: reservation.arrivalFlight,
          departureTime: reservation.departureTime,
          departureLocation: reservation.departureLocation,
          departureFlight: reservation.departureFlight,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {}
  };

  const handleClick = () => {
    props.setIsLoading && props.setIsLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    input.onchange = handleFile;
    input.click();
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: user.permissions.canAddReservation ? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
            marginLeft: props.marginLeft || "0px",
            marginRight: props.marginRight || "0px",
          }}
          onClick={handleClick}
        >
          <Upload />
          <span>Ανέβασε Κρατήσεις</span>
        </button>
      </div>
    </>
  );
};
export default ImportReservationButton;
