import React from "react";

// import { ActionSettingsForm } from "../../components";
// import rh_logo from "../../images/rh_logo.png";
import { PageStructure } from "../../pages";

import "./ActionSettings.css";
import { AddActionOption, UpdateActionOption } from "../../components";

const ActionSettings = () => {
  return (
    <PageStructure>
      <h1>Ρυθμίσεις Δραστηριοτήτων</h1>

      <hr />
     <AddActionOption />
     <UpdateActionOption />
      
    </PageStructure>
  );
};

export default ActionSettings;
