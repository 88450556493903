import React from "react";

// import { ActionSettingsForm } from "../../components";
// import rh_logo from "../../images/rh_logo.png";
import { PageStructure } from "../../pages";

import {
  AddChecklistTemplateButton,
  UpdateActionOption,
  UpdateChecklistTemplate,
} from "../../components";

const ChecklistSettings = () => {
  return (
    <PageStructure>
      <h1>Ρυθμίσεις Λιστών Ελέγχου</h1>

      <hr />

      <AddChecklistTemplateButton />
      <UpdateChecklistTemplate />
    </PageStructure>
  );
};

export default ChecklistSettings;
