import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Checklist.css";

import {
  ImportReservationButton,
  Sidebar,
  TaskManager,
  ReservationTable,
  Loader,
  AddReservationButton,
  ChecklistTable,
} from "../../components";
import { Add, Task } from "@mui/icons-material";
import { PageStructure } from "../../pages";

const Checklist = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PageStructure>
      <h1>Λίστες ελέγχου</h1>

      {isLoading && <Loader />}
      <div
        style={
          {
            // visibility: isLoading ? "hidden" : "visible",
          }
        }
      >

        <div
        style={
          {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            overflowX: "auto",

          }
        }
        >

        </div>

        <ChecklistTable />
      </div>
    </PageStructure>
  );
};

export default Checklist;
