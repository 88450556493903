import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Calendar.css";
import axios from "axios";
import io from "socket.io-client";
import { AddActionForm, PopUpWindow, ViewActionsForm } from "../../components";

import { Tooltip } from "@mui/material";

const socket = io("https://www.maliarakis-server.rhodesislandpass.com/");

function formatDateString(date) {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function Calendar() {
  const [data, setData] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionsOptions, setActionsOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [actions, setActions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);

  const [createActionPopUpWindowIsOpen, setCreateActionPopUpWindowIsOpen] =
    useState(false);
  const [createActionSelectedDate, setCreateActionSelectedDate] = useState(
    new Date()
  );
  const [createActionSelectedProperty, setCreateActionSelectedProperty] =
    useState(null);

  const [viewActionsPopUpWindowIsOpen, setViewActionsPopUpWindowIsOpen] =
    useState(false);
  const [viewActionsSelectedDate, setViewActionsSelectedDate] = useState(
    new Date()
  );
  const [viewActionsSelectedActions, setViewActionsSelectedActions] = useState(
    []
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHotel, setSelectedHotel] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  const [searchTerm, setSearchTerm] = useState("");

  const [dates, setDates] = useState(
    Array.from(
      { length: 30 },
      (_, i) =>
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + i
        )
    )
  );

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://www.maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchReservations = async () => {
    try {
      const response = await axios.get(
        `https://www.maliarakis-server.rhodesislandpass.com/reservation/getReservationByDateRange?startDate=${
          dates[0]
        }&endDate=${dates[dates.length - 1]}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReservations(response.data.reservations);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchActionsOptions = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActionsOptions(response.data.actionOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/property/getPropertiesNames",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchActions = async () => {
    try {
      const response = await axios.get(
        `https://www.maliarakis-server.rhodesislandpass.com/action/getActionByRange?startDate=${
          dates[0] - 1
        }&endDate=${dates[dates.length - 1]}`,

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActions(response.data.actions);
    } catch (error) {
      console.error(error);
    }
  };

  const updateAction = async (actionId, propertyId, date) => {
    try {
      await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/action/updateAction/${actionId}`,
        {
          planned_start_date: date,
          property: propertyId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchActionsOptions();
    fetchProperties();
    fetchGroups();
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchReservations();
    fetchActions();
  }, [dates]);

  useEffect(() => {
    setData(
      properties.map((property) => ({
        ...property,
        reservations: reservations
          .filter((reservation) => reservation.property === property.name)
          .map((reservation) => ({
            startDate: new Date(reservation.checkIn),
            endDate: new Date(reservation.checkOut),
          })),

        actions: actions
          .filter((action) => action.property === property._id)
          .map((action) => ({
            planned_start_date: new Date(action.planned_start_date),
            group: action.group,
            assigned_to: action.assigned_to,
            action_option: action.action_option,
            name: actionsOptions.find(
              (option) => option._id === action.action_option
            ).name,
            _id: action._id,
            color: actionsOptions.find(
              (option) => option._id === action.action_option
            ).color,
            date: new Date(action.planned_start_date),
            price: action.price,
            notes: action.notes,
          })),
      }))
    );
    setIsLoading(false);
  }, [reservations, actions, properties]);

  const changeDate = (offset) => {
    setDates(
      dates.map(
        (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate() + offset)
      )
    );
  };

  const handleDrop = async (sourceHotelId, targetHotelId, oldDate, newDate) => {
    const prevActions = data
      .find((hotel) => hotel._id === sourceHotelId)
      .actions.filter((action) => action.date.getDate() === oldDate.getDate());

    const newActions = data
      .find((hotel) => hotel._id === targetHotelId)
      .actions.filter((action) => action.date.getDate() === newDate.getDate());

    prevActions.forEach(async (prevAction) => {
      const newAction = {
        ...prevAction,
        date: newDate,
      };

      await updateAction(newAction._id, targetHotelId, newDate);
    });

    const sourceHotel = data.find((hotel) => hotel._id === sourceHotelId);
    const targetHotel = data.find((hotel) => hotel._id === targetHotelId);

    const sourceHotelActions = sourceHotel.actions.filter(
      (action) => action.date.getDate() !== oldDate.getDate()
    );

    const targetHotelActions = targetHotel.actions;

    const newData = data.map((hotel) => {
      if (sourceHotelId === targetHotelId && hotel._id === sourceHotelId) {
        return {
          ...hotel,
          actions: [
            ...sourceHotelActions,
            ...prevActions.map((action) => ({
              ...action,
              date: newDate,
            })),
          ],
        };
      }

      if (hotel._id === sourceHotelId) {
        return {
          ...hotel,
          actions: sourceHotelActions,
        };
      } else if (hotel._id === targetHotelId) {
        return {
          ...hotel,
          actions: [
            ...targetHotelActions,
            ...prevActions.map((action) => ({
              ...action,
              date: newDate,
            })),
          ],
        };
      }
      return hotel;
    });

    setData(newData);
  };

  return (
    <div className="calendar-wrapper">
      {createActionPopUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setCreateActionPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
        >
          <AddActionForm
            date={
              new Date(
                createActionSelectedDate.getFullYear(),
                createActionSelectedDate.getMonth(),
                createActionSelectedDate.getDate() + 1
              )
            }
            property={createActionSelectedProperty}
          />
        </PopUpWindow>
      )}

      <div className="calendar-buttons-wrapper">
      <div className="calendar-buttons-days">
        <button className="calendar-button" onClick={() => changeDate(-15)}>
          Προηγούμενες 15
        </button>
        <button className="calendar-button" onClick={() => changeDate(15)}>
          Έπομενες 15
        </button>
      </div>

      <div className="calendar-buttons-search">
      <Tooltip title="Δίαλεξε Ημερομηνία">
          <input
            type="date"
            value={selectedDate.toISOString().split("T")[0]}
            onChange={(e) => {
              setSelectedDate(new Date(e.target.value));
              setDates(
                Array.from(
                  { length: 30 },
                  (_, i) =>
                    new Date(
                      new Date(e.target.value).getFullYear(),
                      new Date(e.target.value).getMonth(),
                      new Date(e.target.value).getDate() + i
                    )
                )
              );
            }}
            style={{
              height: "30px",
              width: "300px",
              fontSize: "16px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              padding: "5px",
              margin: "0 10px",
              cursor: "pointer",
              backgroundColor: "#f8f8f8",
              color: "#333",
              outlineColor: "var(--primary-color)",

              fontFamily: "Arial",
            }}
          />
        </Tooltip>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Αναζήτηση με όνομα..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            style={{
              padding: "10px",
              margin: "10px 0",
              width: "300px",
              fontSize: "16px",
              outlineColor: "var(--primary-color)",
            }}
          />
        </div>
      </div>
      </div>
      {!isLoading && (
        <>
          <div className="calendar-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th className="name-column">Όνομα</th>
                  {dates.map((date) => (
                    <th key={date.toISOString()}>{date.toDateString()}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter((hotel) =>
                    hotel.name.toLowerCase().includes(searchTerm)
                  )
                  .map((hotel) => (
                    <Row
                      key={hotel._id}
                      hotel={hotel}
                      dates={dates}
                      handleDrop={handleDrop}
                      setCreateActionPopUpWindowIsOpen={
                        setCreateActionPopUpWindowIsOpen
                      }
                      setCreateActionSelectedDate={setCreateActionSelectedDate}
                      setCreateActionSelectedProperty={
                        setCreateActionSelectedProperty
                      }
                      setViewActionsPopUpWindowIsOpen={
                        setViewActionsPopUpWindowIsOpen
                      }
                      setViewActionsSelectedDate={setViewActionsSelectedDate}
                      setViewActionsSelectedActions={
                        setViewActionsSelectedActions
                      }
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {viewActionsPopUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setViewActionsPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
          title={`Δραστηριότητες - ${formatDateString(
            viewActionsSelectedDate
          )} - ${createActionSelectedProperty.label}`}
        >
          <ViewActionsForm
            users={users}
            groups={groups}
            selectedActions={viewActionsSelectedActions}
            date={
              new Date(
                viewActionsSelectedDate.getFullYear(),
                viewActionsSelectedDate.getMonth(),
                viewActionsSelectedDate.getDate() + 1,
                viewActionsSelectedDate.getHours(),
                viewActionsSelectedDate.getMinutes(),
                viewActionsSelectedDate.getSeconds()
              )
            }
            property={createActionSelectedProperty}
          />
        </PopUpWindow>
      )}
    </div>
  );
}

function Row({
  hotel,
  dates,
  handleDrop,
  setCreateActionPopUpWindowIsOpen,
  setCreateActionSelectedDate,
  setCreateActionSelectedProperty,
  setViewActionsPopUpWindowIsOpen,
  setViewActionsSelectedDate,
  setViewActionsSelectedActions,
}) {
  // console.log(hotel);
  const handleDragStart = (event, date, hotelId) => {
    const actionsOnDate = hotel.actions.filter(
      (action) => action.date.getDate() === date.getDate()
    );

    const dragData = {
      sourceHotelId: hotelId,
      actions: actionsOnDate.map((action) => ({
        actionId: action._id,
        date: action.planned_start_date,
      })),
      date: date, // This might not be needed depending on your use case
    };
    event.dataTransfer.setData("application/json", JSON.stringify(dragData));
  };

  const onDrop = (event, targetDate) => {
    event.preventDefault(); // This is crucial for enabling the drop event
    // console.log("drag data", event.dataTransfer.getData("application/json"));
    const dragData = JSON.parse(event.dataTransfer.getData("application/json"));
    const oldDate = new Date(dragData.date);
    handleDrop(dragData.sourceHotelId, hotel._id, oldDate, targetDate);
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Allow the drop by preventing the default behavior
  };

  const cellStyle = (date) => {


    const isStartDate = hotel.reservations.some(
      (reservation) =>`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` === `${reservation.startDate.getDate()}/${reservation.startDate.getMonth() + 1}/${reservation.startDate.getFullYear()}`
    );
    const isEndDate = hotel.reservations.some(
      (reservation) =>`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` === `${reservation.endDate.getDate()}/${reservation.endDate.getMonth() + 1}/${reservation.endDate.getFullYear()}`
    );

    const colliding = hotel.reservations.some(
      (reservation) =>
        date.getTime() === reservation.startDate.getTime() 
      &&
        hotel.reservations.some((r) => date.getTime() === r.endDate.getTime())
    );

    if (colliding) {
    if (hotel.name === "ΣΠΙΡΤΟΚΟΥΤΟ") {

      console.log("colliding", colliding);
      console.log("date", date);
      console.log("hotel", hotel);
    }

      return { className: "colliding" }; // Apply colliding class if start and end date are the same
    } else if (isStartDate) {

   
      

      return { borderRadius: "50px 0 0 0", position: "relative" };
    } else if (isEndDate) {
      return { borderRadius: "0 0 50px 0", position: "relative" };
    } else {
      return { position: "relative" };
    }
  };

  const formatCell = (date) => {
    const isActive = hotel.reservations.some(
      (reservation) =>
        // date.getTime() >= reservation.startDate.getTime() &&
        // date.getTime() <= reservation.endDate.getTime()

        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
        >= new Date(
          reservation.startDate.getFullYear(),
          reservation.startDate.getMonth(),
          reservation.startDate.getDate()
        )
        &&
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
        <= new Date(
          reservation.endDate.getFullYear(),
          reservation.endDate.getMonth(),
          reservation.endDate.getDate()
        )
        
    );
    const actions = hotel.actions.filter(
      (action) => date.getDate() === action.date.getDate()
    );

    return {
      booked: isActive,
      actions: actions, // Return all actions for the date
    };
  };

  function useSingleAndDoubleClick(
    actionSimpleClick,
    actionDoubleClick,
    delay = 250
  ) {
    const timeoutRef = useRef(null);
    const clickCountRef = useRef(0);

    const eventHandler = useCallback(
      (date, hotel, actions) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        clickCountRef.current += 1;

        timeoutRef.current = setTimeout(() => {
          if (clickCountRef.current === 1) {
            actionSimpleClick(date, hotel, actions);
          } else if (clickCountRef.current > 1) {
            actionDoubleClick(date, hotel);
          }
          clickCountRef.current = 0;
        }, delay);
      },
      [actionSimpleClick, actionDoubleClick, delay]
    );

    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    return eventHandler;
  }

  const handleSingleClick = useCallback((date, property, actions) => {
    if (property) {
      setViewActionsPopUpWindowIsOpen(true);
      setViewActionsSelectedDate(date);
      setCreateActionSelectedProperty({
        value: {
          _id: property._id,
          name: property.name,
        },
        label: property.name,
      });
      setViewActionsSelectedActions(
        actions.map((action) => {
          return {
            _id: action._id,
            name: action.name,
            action_option: action.action_option,
            group: action.group,
            assigned_to: action.assigned_to,
            planned_start_date: action.planned_start_date,
            price: action.price,
            notes: action.notes,
          };
        })
      );
    } else {
      console.error("Property data is undefined.");
    }
  }, []);

  const handleDoubleClick = useCallback(
    (date, property) => {
      console.log("Double click on", date, "with property:", property);
      if (property) {
        // Check if property is not undefined
        setCreateActionSelectedDate(date);
        setCreateActionSelectedProperty({
          value: {
            _id: property._id,
            name: property.name,
          },
          label: property.name,
        });
        setCreateActionPopUpWindowIsOpen(true);
      } else {
        console.error("Property data is undefined.");
      }
    },
    [
      setCreateActionSelectedDate,
      setCreateActionSelectedProperty,
      setCreateActionPopUpWindowIsOpen,
    ]
  );

  const clickHandler = useSingleAndDoubleClick(
    handleSingleClick,
    handleDoubleClick
  );

  const [popupWindowIsOpen, setPopupWindowIsOpen] = useState(true);

  return (
    <tr>
      <td className="name-column">{hotel.name}</td>
      {dates.map((date) => {
        const status = formatCell(date);
        const styleInfo = cellStyle(date); // Get the style information

        return (
          <td
            key={date.toISOString()}
            className={`${status.booked ? "booked" : ""} ${
              styleInfo.className || ""
            }`}
            style={styleInfo}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => onDrop(e, date)}
            onClick={() => {
              clickHandler(date, hotel, status.actions);
            }}
          >
            {status.actions && status.actions.length >= 2 && (
              <Tooltip
                title={status.actions.map((action) => action.name).join(", ")}
                placement="top"
                arrow
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundImage: `linear-gradient(to right, ${status.actions
                      .map((action) => action.color)
                      .join(", ")})`,

                    opacity: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  draggable="true" // Enable dragging
                  onDragStart={(e) => handleDragStart(e, date, hotel._id)}
                  // Use the existing handleDragStart method
                >
                  <span style={{ color: "white" }}>
                    {status.actions.length}
                  </span>
                </div>
              </Tooltip>
            )}

            {status.actions &&
              status.actions.length < 2 &&
              status.actions.map((action) => (
                <Tooltip
                  title={action.name}
                  placement="top"
                  arrow
                  key={action._id}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    key={action._id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, date, hotel._id)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: action.color,
                      opacity: 0.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  ></div>
                </Tooltip>
              ))}
          </td>
        );
      })}
    </tr>
  );
}

export default Calendar;
