import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  Auth,
  Dashboard,
  Properties,
  Reservations,
  TicketSettings,
  Tickets,
  Inbox,
  Groups,
  Users,
  ActionSettings,
  Calendar,
  ChecklistSettings,
  Checklist, 
  Actions
} from "./pages";
import NotificationSound from "./sounds/notification-sound.mp3";

import "./App.css";



const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#63B330", // Replace with your primary color
    },
    secondary: {
      main: "#B3CC27", // Replace with your secondary color
    },
    tertiary: {
      main: "#285c1f", // Replace with your tertiary color
    },
  },
});
function App() {

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

 

  return (
    <div className="App">
      <UserProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" element={<Auth />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/tickets/assigned-to-me"
                element={
                  <Tickets
                    key="assigned-to-me"
                    subtitle="Tickets Ανατεθειμένα σε μένα"
                    assignedTo={JSON.parse(localStorage.getItem("user"))}
                    searchStatus={[
                      {
                        value: {
                          color: "#09fbf7",
                          title: "On Progress",
                          __v: 0,
                          _id: "660be69afebd962dd37c663d",
                        },
                        label: "On Progress",
                      },
                      {
                        value: {
                          color: "#11ff00",
                          title: "Open",
                          __v: 0,
                          _id: "65fdd8c6ab895f5bb3aa645b",
                        },
                        label: "Open",
                      },
                    ]}
                  />
                }
              />
              <Route
                path="/tickets/archived"
                element={
                  <Tickets
                    subtitle="Αρχειοθετημένα Tickets"
                    archived={true}
                    key="archived"
                  />
                }
              />
              <Route
                path="/tickets/completed"
                element={
                  <Tickets
                    subtitle="Completed Tickets"
                    searchStatus={[
                      {
                        value: {
                          color: "#1909fb",
                          title: "Completed",
                          _id: "65fdd8ccab895f5bb3aa645e",
                        },
                        label: "Completed",
                      },
                    ]}
                  />
                }
              />
              <Route
                path="/tickets/on-progress"
                element={
                  <Tickets
                    subtitle="On Progress Tickets"
                    key="on-progress"
                    searchStatus={[
                      {
                        value: {
                          color: "#09fbf7",
                          title: "On Progress",
                          __v: 0,
                          _id: "660be69afebd962dd37c663d",
                        },
                        label: "On Progress",
                      },
                    ]}
                  />
                }
              />

              <Route
                path="/tickets/open"
                element={
                  <Tickets
                    subtitle="Open Tickets"
                    key="open"
                    searchStatus={[
                      {
                        value: {
                          color: "#11ff00",
                          title: "Open",
                          __v: 0,
                          _id: "65fdd8c6ab895f5bb3aa645b",
                        },
                        label: "Open",
                      },
                    ]}
                  />
                }
              />
              <Route
                path="/tickets/completed"
                element={
                  <Tickets
                    subtitle="Completed Tickets"
                    key="completed"
                    searchStatus={[
                      {
                        value: {
                          color: "#1909fb",
                          title: "Completed",
                          _id: "65fdd8ccab895f5bb3aa645e",
                        },
                        label: "Completed",
                      },
                    ]}
                  />
                }
              />
              <Route
                path="/tickets/all"
                element={<Tickets subtitle="All Tickets" key="all" />}
              />
              <Route
                path="/tickets/created-by-me"
                element={
                  <Tickets
                    subtitle="Tickets Δημιουργημένα από εμένα"
                    key="byme"
                    createdBy={true}
                  />
                }
              />
            
              <Route path="/tickets/settings" element={<TicketSettings />} />

              <Route path="/reservations" element={<Reservations />} />
              <Route path="/reservations/all" element={<Reservations />} />

              <Route path="/inbox" element={<Inbox />} />
              <Route path="/inbox/all" element={<Inbox />} />
              <Route path="/inbox/:chatroomId" element={<Inbox />} />

              <Route path="/groups" element={<Groups />} />
              <Route path="/users" element={<Users />} />

              <Route path="/properties" element={<Properties />} />

              <Route path="/actions/settings" element={<ActionSettings />} />
              <Route path="/calendar" element={<Calendar />} />

              <Route path="/checklists/settings" element={<ChecklistSettings />} />
              <Route path="/checklists/all" element={<Checklist/>} />

              <Route path="/actions/all" element={<Actions 
                key="all"
              />} />
              <Route path="/actions/pending" element={<Actions key={
                "pending"
              
              } />} />
              <Route path="/actions/completed" element={<Actions
                key="completed"
              />} />
              
                
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </UserProvider>
    </div>
  );
}

export default App;
