import React, { useState, useEffect } from "react";
import axios, { all } from "axios";
import { Box, Avatar, Tooltip, Chip } from "@mui/material";
import {
  Article,
  Download,
  Send,
  AttachFile,
  Delete,
} from "@mui/icons-material";
import Select, { components } from "react-select";
import io from "socket.io-client";
import "./viewTicketForm.css";
import StyledTextarea from "../styledTextarea/StyledTextarea";

// Function to generate a color based on the username
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "right"}
      arrow
      PopperProps={{
        style: { zIndex: 10002 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const AssignedToCell = ({ userIds, allUsers }) => {
  // Placeholder for fetching user names based on IDs
  // In a real application, replace this with actual logic to fetch user details
  const users = userIds.map(
    (userId) =>
      allUsers.find((user) => user._id === userId) || { name: "Unknown" }
  );

  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      {users.map((user) => (
        <Tooltip
          key={user.id}
          title={user.name}
          arrow
          PopperProps={{
            style: { zIndex: 10001 },
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
            sx: {
              "& .MuiTooltip-tooltip": {
                fontSize: "14px",
              },
            },
          }}
        >
          <Avatar style={{ backgroundColor: getColor(user.name) }}>
            {user.name[0]}
          </Avatar>
        </Tooltip>
      ))}
    </Box>
  );
};

const findDueDateColor = (dueDate) => {
  const date = new Date(dueDate);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#FF0000";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const formatDateWithTime = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const socket = io("https://www.maliarakis-server.rhodesislandpass.com/");

const checkFileType = (fileString) => {
  return fileString.split(".").pop().toLowerCase();
};

const isImageFile = (fileString) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const fileExtension = checkFileType(fileString);
  return imageExtensions.includes(fileExtension);
};

const sitelocation = "https://www.maliarakis-server.rhodesislandpass.com/";

const ViewTicketForm = (props) => {
  const [ticket, setTicket] = useState(props.ticket); // Assuming this is the ticket object you're passing as a prop
  const statuses = props.statuses;
  const priorities = props.priorities;
  const categories = props.categories;
  const properties = props.properties;
  const users = props.users;
  const groups = props.groups;

  const user = JSON.parse(localStorage.getItem("user"));

  const [reservation, setReservation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    users
      .map((u) => ({ value: u, label: u.username, tooltip: u.name }))
      .filter((u) => ticket.assignedTo.includes(u.value._id))
  );
  const [selectedGroups, setSelectedGroups] = useState(
    groups
      .map((g) => ({ value: g, label: g.name, tooltip: g.name }))
      .filter((g) => ticket.groupAssignedTo.flat().includes(g.value._id))
  );

  const handleGroupChange = async (newSelectedGroups) => {
    let newUsers = newSelectedGroups.flatMap((group) =>
      group.value.users.map((user) => ({
        value: user,
        label: user.username,
        tooltip: user.name,
      }))
    );

    setSelectedUsers((prevSelectedUsers) => {
      const uniqueUsers = newUsers.filter((newUser) => {
        return !prevSelectedUsers.some((selectedUser) => {
          return selectedUser.value._id === newUser.value._id;
        });
      });
      return [...prevSelectedUsers, ...uniqueUsers];
    });

    setIsLoading(true);

    try {
      await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/ticket/updateTicket/${ticket._id}`,
        {
          groupAssignedTo: newSelectedGroups.map((group) => {
            return { _id: group.value._id ,
              name: group.value.name,
            };
          }),
          assignedTo: [
            ...selectedUsers.map((user) => user.value._id),
            ...newSelectedGroups.flatMap((group) =>
              group.value.users.map((user) => user._id)
            ),
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(ticket.status);
  const [selectedPriority, setSelectedPriority] = useState(ticket.priority);
  const [commentText, setCommentText] = useState("");
  const fileInputRef = React.createRef();
  const [textareaKey, setTextareaKey] = useState(Date.now());

  useEffect(() => {
    const handleUpdatedTicket = (updatedTicket) => {


      if (updatedTicket._id === ticket._id) {
        setTicket(updatedTicket);
      }
    };

    socket.on("ticketUpdated", handleUpdatedTicket);

    return () => {
      socket.off("ticketUpdated", handleUpdatedTicket);
    };
  }, []);

  useEffect(() => {
    const fetchReservation = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://www.maliarakis-server.rhodesislandpass.com/reservation/getReservation/${ticket.reservationId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setReservation(response.data.reservation);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    ticket.reservationId && fetchReservation();
  }, []);

  useEffect(() => {
    const commentSection = document.querySelector(
      ".card-main-comments-section"
    );
    commentSection.scrollTop = commentSection.scrollHeight;
  }, [ticket.comments]);

  useEffect(() => {
    setSelectedUsers(
      users
        .map((u) => ({ value: u, label: u.username, tooltip: u.name }))
        .filter((u) => ticket.assignedTo.includes(u.value._id))
    );

    setSelectedStatus(ticket.status);
    setSelectedPriority(ticket.priority);
  }, [ticket]);

  const clearComment = () => {
    setCommentText("");
    setSelectedFiles([]);
  };
  // Adjusted to handle multiple file selections
  const handleFileSelection = (event) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to Array
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append new files
  };
  // Remove a file based on its index
  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleCommentUpload = async (files, commentText) => {
    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("attachments", files[i]);
      }
    }
    formData.append("comment", commentText);

    try {
      const response = await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/ticket/addComment/${ticket._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setTextareaKey(Date.now());
      clearComment();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCommentChange = (commentText) => {
    setCommentText(commentText);
  };
  const handleAssignTo = async (newSelectedUsers) => {
    setIsLoading(true);
    const assignTo = newSelectedUsers.map((u) => u.value._id);

    try {
      await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/ticket/updateTicket/${ticket._id}`,
        {
          assignedTo: assignTo,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatusChange = async (newStatus) => {
    setIsLoading(true);
    try {
      await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/ticket/updateTicket/${ticket._id}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,

            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }

    setSelectedStatus(newStatus);
  };

  const parseAndHighlightUsernames = (text, allUsers) => {
    const usernamePattern = /@(\w+)/g;
    const allUsernames = allUsers.map((user) => user.username); // Assuming your user objects have a username field

    return text.split(usernamePattern).map((part, index) => {
      if (index % 2 === 1 && allUsernames.includes(part)) {
        return (
          <span
            key={index}
            style={{
              backgroundColor: "#b5c2cf",
              color: "#45505a",
              textDecoration: "underline",
            }}
          >
            @{part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h1>{ticket.title}</h1>
        <p
          style={{
            margin: "0",
            padding: "0",
          }}
        >
          {ticket._id}
        </p>
        <h2>{properties.find((p) => p._id === ticket.property)?.name}</h2>
      </div>

      <div className="card-body">
        <div className="card-main">
          <div className="seperator" />
          <div className="card-main-description">
            <h3>Description</h3>
            <p>
              {ticket.description}
              {!ticket.reservationId && <br />}
              {!ticket.reservationId ? "More Info: " : ""}
              {ticket.customerFirstName !== "null" && !ticket.reservationId
                ? ticket.customerFirstName
                : ""}{" "}
              {ticket.customerLastName !== "null" && !ticket.reservationId
                ? " "
                : ""}
              {ticket.customerLastName !== "null" && !ticket.reservationId
                ? ticket.customerLastName
                : ""}
              {(ticket.customerFirstName && !ticket.reservationId !== "null") ||
              (ticket.customerLastName && !ticket.reservationId !== "null") ? (
                <br />
              ) : (
                ""
              )}
              {ticket.checkInDate && !ticket.reservationId
                ? `Check in: ${formatDate(ticket.checkInDate)}`
                : ""}
              {ticket.checkInDate &&
              ticket.checkOutDate &&
              !ticket.reservationId
                ? " - "
                : ""}
              {ticket.checkOutDate && !ticket.reservationId
                ? `Check out: ${formatDate(ticket.checkOutDate)}`
                : ""}
            </p>
          </div>

          {reservation.reservationId && (
            <>
              <div className="seperator" />

              <div className="card-main-reservation">
                <h3>Reservation</h3>
                <h4>Reservation ID:</h4>
                <p> {reservation.reservationId}</p>
                <h4>Check In - Check Out Date:</h4>
                <p>
                  {formatDate(reservation.checkIn)}
                  {" - "}
                  {formatDate(reservation.checkOut)}
                </p>
                <h4>More Info: </h4>
                <p>
                  {reservation.firstName} {reservation.lastName}
                  {" - "}
                  {reservation.email} {" - "} {reservation.phone} <br />
                  {reservation.staffNotes}
                  {reservation.staffNotes ? " - " : ""}
                  {reservation.arrivalTime}
                  {reservation.arrivalTime ? " - " : ""}
                  {reservation.arrivalLocation}
                  {reservation.arrivalLocation ? " - " : ""}
                  {reservation.arrivalFlight}
                  {reservation.arrivalFlight ? " - " : ""}
                  {reservation.departureTime}
                  {reservation.departureTime ? " - " : ""}
                  {reservation.departureLocation}
                  {reservation.departureLocation ? " - " : ""}
                  {reservation.departureFlight}
                </p>
              </div>
            </>
          )}
          {ticket.attachments.length > 0 && (
            <>
              <div className="seperator" />
              <div className="card-main-attachments">
                <h3>Attachments</h3>
                <div className="card-main-attachments-images">
                  {ticket.attachments
                    .filter((attachment) => isImageFile(attachment))
                    .map((attachment) => (
                      <img
                        src={sitelocation + attachment}
                        alt={attachment}
                        key={attachment}
                        onClick={() => {
                          window.open(sitelocation + attachment, "_blank");
                        }}
                      />
                    ))}
                </div>
                <div className="card-main-attachments-files">
                  {ticket.attachments
                    .filter((attachment) => !isImageFile(attachment))
                    .map((attachment) => (
                      <p
                        key={attachment}
                        onClick={() => {
                          window.open(sitelocation + attachment, "_blank");
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Article />{" "}
                        {attachment.slice(attachment.lastIndexOf("/") + 1)}{" "}
                        <Download />
                      </p>
                    ))}
                </div>
              </div>
            </>
          )}
          <div className="seperator" />
          <div className="card-main-comments">
            <h3>Comments</h3>

            <div className="card-main-comments-section">
              {[...ticket.comments].reverse().map((comment) => (
                <div key={comment._id} className="comment">
                  {comment.type === "event" ? (
                    <div className="comment-event">
                      <div className="comment-event-title">
                        <AssignedToCell
                          userIds={[comment.creator]}
                          allUsers={users}
                        />
                        {/* {comment.text} */}{" "}
                        {parseAndHighlightUsernames(comment.text, users)}
                      </div>
                      <div className="comment-date">
                        {formatDateWithTime(comment.date)}
                      </div>
                    </div>
                  ) : (
                    <div className="comment-text">
                      <div className="comment-text-title">
                        {" "}
                        <AssignedToCell
                          userIds={[comment.creator]}
                          allUsers={users}
                        />
                        {parseAndHighlightUsernames(comment.text, users)}
                      </div>

                      <div className="comment-date">
                        {formatDateWithTime(comment.date)}
                      </div>
                      <div className="comment-attachments">
                        {comment.attachments?.filter((attachment) =>
                          isImageFile(attachment)
                        ).length > 0 && (
                          <div className="comment-attachments-images">
                            {comment.attachments
                              .filter((attachment) => isImageFile(attachment))
                              .map((attachment) => (
                                <img
                                  src={sitelocation + attachment}
                                  alt={attachment}
                                  key={attachment}
                                  onClick={() => {
                                    window.open(
                                      sitelocation + attachment,
                                      "_blank"
                                    );
                                  }}
                                />
                              ))}
                          </div>
                        )}
                        {comment.attachments?.filter(
                          (attachment) => !isImageFile(attachment)
                        ).length > 0 && (
                          <div className="comment-attachments-files">
                            {comment.attachments
                              .filter((attachment) => !isImageFile(attachment))
                              .map((attachment) => (
                                <p
                                  key={attachment}
                                  onClick={() => {
                                    window.open(
                                      sitelocation + attachment,
                                      "_blank"
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",

                                    gap: "5px",
                                  }}
                                >
                                  <Article />{" "}
                                  {attachment.slice(
                                    attachment.lastIndexOf("/") + 1
                                  )}{" "}
                                  <Download />
                                </p>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="card-main-add-comment">
              <StyledTextarea
                reset={textareaKey}
                users={users}
                onCommentChange={handleCommentChange}
              />

              <div
                className="card-main-add-comment-buttons"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Tooltip
                  title="Attach File"
                  PopperProps={{
                    style: { zIndex: 10001 },
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: "14px",
                      },
                    },
                  }}
                >
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelection}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />

                  <AttachFile
                    sx={[{ color: "#B5C2CF" }, { cursor: "pointer" }]}
                    onClick={() => fileInputRef.current.click()}
                  />
                </Tooltip>

                <Tooltip title="Send" arrow>
                  <Send
                    sx={[{ color: "#B5C2CF" }, { cursor: "pointer" }]}
                    onClick={() => {
                      // Send the comment

                      if (commentText || selectedFiles.length > 0) {
                        handleCommentUpload(selectedFiles, commentText);
                      }
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          {selectedFiles.length > 0 && (
            <div className="file-list">
              <p>Selected Files:</p>
              <ul>
                {selectedFiles.map((file, index) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {file.name}
                    <Tooltip
                      title="Remove File"
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            fontSize: "15px",
                          },
                        },
                        style: { zIndex: 10001, fontSize: "16px" },
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      }}
                      arrow
                      placement="right"
                    >
                      <Delete
                        sx={{
                          color: "#b5c2cf",
                          fontSize: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            color: "var(--secondary-color)",
                          },
                          transition: "color 0.3s",
                          alignSelf: "center",
                          textAlign: "center",

                          padding: "10px",
                        }}
                        onClick={() => removeFile(index)}
                      />
                    </Tooltip>
                    {/* Adjust styling as needed */}
                  </li>
                ))}
              </ul>
              <p>Total Files: {selectedFiles.length}</p>
            </div>
          )}

          <div className="seperator" />
          <h3>Timeline</h3>
          <div className="card-main-comments-section">
              {[...ticket.timeline].reverse().map((comment) => (
                <div key={comment._id} className="comment">
                  {comment.type === "event" ? (
                    <div className="comment-event">
                      <div className="comment-event-title">
                        <AssignedToCell
                          userIds={[comment.creator]}
                          allUsers={users}
                        />
                        {/* {comment.text} */}{" "}
                        {parseAndHighlightUsernames(comment.text, users)}
                      </div>
                      <div className="comment-date">
                        {formatDateWithTime(comment.date)}
                      </div>
                    </div>
                  ) : (
                    <div className="comment-text">
                      <div className="comment-text-title">
                        {" "}
                        <AssignedToCell
                          userIds={[comment.creator]}
                          allUsers={users}
                        />
                        {parseAndHighlightUsernames(comment.text, users)}
                      </div>

                      <div className="comment-date">
                        {formatDateWithTime(comment.date)}
                      </div>
                      <div className="comment-attachments">
                        {comment.attachments?.filter((attachment) =>
                          isImageFile(attachment)
                        ).length > 0 && (
                          <div className="comment-attachments-images">
                            {comment.attachments
                              .filter((attachment) => isImageFile(attachment))
                              .map((attachment) => (
                                <img
                                  src={sitelocation + attachment}
                                  alt={attachment}
                                  key={attachment}
                                  onClick={() => {
                                    window.open(
                                      sitelocation + attachment,
                                      "_blank"
                                    );
                                  }}
                                />
                              ))}
                          </div>
                        )}
                        {comment.attachments?.filter(
                          (attachment) => !isImageFile(attachment)
                        ).length > 0 && (
                          <div className="comment-attachments-files">
                            {comment.attachments
                              .filter((attachment) => !isImageFile(attachment))
                              .map((attachment) => (
                                <p
                                  key={attachment}
                                  onClick={() => {
                                    window.open(
                                      sitelocation + attachment,
                                      "_blank"
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",

                                    gap: "5px",
                                  }}
                                >
                                  <Article />{" "}
                                  {attachment.slice(
                                    attachment.lastIndexOf("/") + 1
                                  )}{" "}
                                  <Download />
                                </p>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          
        </div>

        <div className="card-side">
          {ticket.dueDate && (
            <div className="card-side-section">
              <h3>Due Date</h3>
              <Chip
                label={formatDate(ticket.dueDate)}
                style={{
                  backgroundColor: findDueDateColor(ticket.dueDate),
                  color: findContrastColor(findDueDateColor(ticket.dueDate)),
                }}
              />
            </div>
          )}
         {
          ticket.gonnaHandleAt &&
         <div className="card-side-section">
            <h3>Going To Handle At</h3>
            <Chip
              label={formatDate(ticket.gonnaHandleAt)}
              style={{
                backgroundColor: findDueDateColor(ticket.gonnaHandleAt),
                color: findContrastColor(
                  findDueDateColor(ticket.gonnaHandleAt)
                ),
              }}
            />
          </div>}

          <div className="card-side-section">
            <h3>Group Assigned To</h3>
            <div style={{ color: "black" }}>
              <Select
                id="groupAssignTo"
                name="assignTo"
                value={selectedGroups}
                onChange={(newSelectedGroups) => {
                  console.log(newSelectedGroups);
                  console.log(selectedGroups);
                  setSelectedGroups(newSelectedGroups);
                  handleGroupChange(newSelectedGroups); // Pass the new state directly
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#45505A",
                    primary25: "#B5C2CF",
                  },
                })}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "#45505A",
                    color: "#B5C2CF",

                    width: "100%",
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    color: "#B5C2CF",
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: "#B5C2CF",
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    backgroundColor: "#B5C2CF",
                  }),
                  multiValueLabel: (styles) => ({
                    ...styles,
                    color: "black",
                  }),
                }}
                options={
                  groups &&
                  groups.map((group) => ({
                    value: group,
                    label: group.name,
                    tooltip: group.name,
                  }))
                } // Add more options as needed
                isMulti
                isClearable
                components={{ Option: CustomOption }}
              />
            </div>
          </div>
          <div className="card-side-section">
            <h3>Users Assigned To</h3>
            <div style={{ color: "black" }}>
              <Select
                id="assignTo"
                name="assignTo"
                value={selectedUsers}
                onChange={(newSelectedUsers) => {
                  setSelectedUsers(newSelectedUsers);
                  handleAssignTo(newSelectedUsers); // Pass the new state directly
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#45505A",
                    primary25: "#B5C2CF",
                  },
                })}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "#45505A",
                    color: "#B5C2CF",

                    width: "100%",
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    color: "#B5C2CF",
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: "#B5C2CF",
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    backgroundColor: "#B5C2CF",
                  }),
                  multiValueLabel: (styles) => ({
                    ...styles,
                    color: "black",
                  }),
                }}
                options={
                  users &&
                  users
                    .map((user) => ({
                      value: user,
                      label: user.username,
                      tooltip: user.name,
                    }))
                    .filter(
                      (u) =>
                        !selectedGroups
                          .flatMap((group) => group.value.users)
                          .map((user) => user._id)
                          .includes(u.value._id)
                    )
                } // Add more options as needed
                isMulti
                isClearable
                components={{ Option: CustomOption }}
              />
            </div>
          </div>
          {ticket.status && (
            <div className="card-side-section">
              <h3>Status</h3>
              <Select
                id="status"
                name="status"
                value={{
                  value: selectedStatus,
                  label: statuses.find((s) => s._id === selectedStatus)?.title,
                }}
                onChange={(newStatus) => {
                  setSelectedStatus(newStatus.value);
                  handleStatusChange(newStatus.value); // Pass the new state directly
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#B5C2CF",
                    primary25: "#45505A",
                  },
                })}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor:
                      statuses.find((s) => s._id === selectedStatus)?.color ||
                      "#45505A",
                    color: findContrastColor(
                      statuses.find((s) => s._id === selectedStatus)?.color ||
                        "#45505A"
                    ),

                    width: "100%",
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    color: findContrastColor(
                      statuses.find((s) => s._id === selectedStatus)?.color ||
                        "#45505A"
                    ),
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: findContrastColor(
                      statuses.find((s) => s._id === selectedStatus)?.color ||
                        "#45505A"
                    ),
                  }),
                  option: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    color: "#45505A",

                    "&:hover": {
                      backgroundColor: "#45505A",
                      color: "#B5C2CF",
                    },
                  }),

                  placeholder: (styles) => ({
                    ...styles,
                    color: findContrastColor(
                      statuses.find((s) => s._id === selectedStatus)?.color ||
                        "#45505A"
                    ),
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    color: findContrastColor(
                      statuses.find((s) => s._id === selectedStatus)?.color ||
                        "#45505A"
                    ),
                  }),
                }}
                options={
                  statuses &&
                  statuses.map((status) => ({
                    value: status._id,
                    label: status.title,
                  }))
                } // Add more options as needed
                // components={{ Option: CustomOption }}
              />
            </div>
          )}
          {ticket.priority && (
            <div className="card-side-section">
              <h3>Priority</h3>
              <Chip
                label={priorities.find((p) => p._id === ticket.priority)?.title}
                style={{
                  backgroundColor: priorities.find(
                    (p) => p._id === ticket.priority
                  )?.color,
                  color: findContrastColor(
                    priorities.find((p) => p._id === ticket.priority)?.color
                  ),
                }}
              />
            </div>
          )}
          {ticket.type && (
            <div className="card-side-section">
              <h3>Type</h3>
              <Chip
                label={categories.find((c) => c._id === ticket.type)?.title}
                style={{
                  backgroundColor: categories.find((c) => c._id === ticket.type)
                    ?.color,
                  color: findContrastColor(
                    categories.find((c) => c._id === ticket.type)?.color
                  ),
                }}
              />
            </div>
          )}

          <div className="card-side-section">
            <h3>Created By</h3>

            <AssignedToCell userIds={[ticket.createdBy]} allUsers={users} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTicketForm;
