import React, { useState, useEffect } from "react";
import axios from "axios";
import Select, { components } from "react-select";
import { Checkbox, Tooltip, Alert } from "@mui/material";
import "./usersPermissions.css";
import Loader from "../loader/Loader";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};
const UsersPermissions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://www.maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user")).token
          }`,
        },
      });
      console.log(response.data.users);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
      setErrorMessage("Failed to fetch users.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };


  const translatePerrmission = (permission) => {
  
    let translatedPermission = permission;
    if (permission.includes("can" || "Can" || 'can')) {
      translatedPermission = translatedPermission.replace("can", "Μπορεί να ");
    }
    if (permission.includes("Create")) {
      translatedPermission = translatedPermission.replace("Create", "Δημιουργήσει ");
    }

    if (permission.includes("Add")) {
      translatedPermission = translatedPermission.replace("Add", "Προσθέσει ");
    }

    if (permission.includes("View")) {
      translatedPermission = translatedPermission.replace("View", "Δει ");
    }

    if (permission.includes("Edit")) {
      translatedPermission = translatedPermission.replace("Edit", "Επεξεργαστεί ");
    }

    if (permission.includes("Delete")) {
      translatedPermission = translatedPermission.replace("Delete", "Διαγράψει ");
    }

    if (permission.includes("Update")) {
      translatedPermission = translatedPermission.replace("Update", "Ενημερώσει ");
    }

    if (permission.includes("Assign")) {
      translatedPermission = translatedPermission.replace("Assign", "Αναθέσει ");
    }

    if (permission.includes("Closed")) {
      translatedPermission = translatedPermission.replace("Closed", "Κλείστα ");
    } 

    if (permission.includes("AllTickets")) {
      translatedPermission = translatedPermission.replace("AllTickets", "Όλα τα Tickets ");
    }
    if (permission.includes("TicketSettings")) {
      translatedPermission = translatedPermission.replace("TicketSettings", "Τις Ρυθμίσεις των Tickets ");
    }

    if (permission.includes("ChangePermissions")) {
      translatedPermission = translatedPermission.replace("ChangePermissions", "Αλλάξει τα Δικαιώματα ");
    }

    if (permission.includes("Reservations")) {
      translatedPermission = translatedPermission.replace("Reservations", "Κρατήσεις ");
    }

    if (permission.includes("Action")) {
      translatedPermission = translatedPermission.replace("Action", "Δράση ");
    }

    if (permission.includes("Property")) {
      translatedPermission = translatedPermission.replace("Property", "Κατάλυμα ");
    }

    if (permission.includes("Reservation")) {
      translatedPermission = translatedPermission.replace("Reservation", "Κράτηση ");
    }

    if (permission.includes("Import")) {
      translatedPermission = translatedPermission.replace("Import", "Ανεβάσει ");
    }

    if (permission.includes("CompletedTickets")) {
      translatedPermission = translatedPermission.replace("CompletedTickets", "Ολοκληρωμένα Tickets ");
    }

    if (permission.includes("OpenTickets")) {
      translatedPermission = translatedPermission.replace("OpenTickets", "Ανοιχτά Tickets ");
    }

    if (permission.includes("Restore")) {
      translatedPermission = translatedPermission.replace("Restore", "Επαναφέρει ");
    }

    if (permission.includes("Prices")) {
      translatedPermission = translatedPermission.replace("Prices", "Τιμές ");
    }

    if (permission.includes("Archive")) {
      translatedPermission = translatedPermission.replace("Archive", "Αρχειοθετήσει ");
    }

    if (permission.includes("OnProgress")) {
      translatedPermission = translatedPermission.replace("OnProgress", "Σε Εξέλιξη ");
    }


    



    return translatedPermission;
  
  }

  const handlePermissionChange = async (permission) => {
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);

    try {
      const response = await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/auth/changePermissions/${selectedUser.value._id}`,
        {
          userId: selectedUser.value,
          permission: permission,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      setSuccessMessage("Permissions updated successfully.");
      setUsers(
        users.map((user) => {
          if (user._id === selectedUser.value._id) {
            return {
              ...user,
              permissions: response.data.permissions,
            };
          }
          return user;
        })
      );
      setSelectedUser({
        ...selectedUser,
        value: {
          ...selectedUser.value,
          permissions: response.data.permissions,
        },
      });
    } catch (error) {
      console.error("Failed to update permissions: ", error);
      setErrorMessage("Failed to update permissions.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading && selectedUser) {
      setPermissions(Object.keys(selectedUser.value.permissions));
    }
  }, [selectedUser]);

  return (
    <div className="users-permissions">
      <Select
        className="users-permissions__select"
        options={users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))}
        onChange={handleUserChange}
        placeholder="Select a user..."
        components={{ Option: CustomOption }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--secondary-color)",
            primary25: "var(--tertiary-color)",
          },
        })}
        isClearable
      />
      {errorMessage && (
        <Alert severity="error" className="users-permissions__error">
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" className="users-permissions__success">
          {successMessage}
        </Alert>
      )}
      {!isLoading && selectedUser && (
        <div className="users-permissions__permissions">
          <h2>Permissions</h2>
          {permissions.map((permission) => (
            <div key={permission} className="users-permissions__permission">
              <Checkbox
                checked={selectedUser.value.permissions[permission]}
                onChange={() => handlePermissionChange(permission)}
              />
              <span>{
                translatePerrmission(permission)
                }</span>
            </div>
          ))}
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default UsersPermissions;
