import React, { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { Add } from "@mui/icons-material";
import { AddActionForm, AddChecklistTemplateForm, AddTicketForm, PopUpWindow } from "../../components";


const AddChecklistTemplateButton = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [popUpWindowIsOpen, setPopUpWindowIsOpen] = useState(false);

  const handleClick = () => {
    
    setPopUpWindowIsOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {popUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setPopUpWindowIsOpen(false);
          }}
          unfocusClose={false}
        >
            {/* <AddTicketForm setShowAddTicketForm={setPopUpWindowIsOpen} /> */}
            <AddChecklistTemplateForm/>
        </PopUpWindow>
      )}
      <div className="add-ticket-button"
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions.canCreateTicket? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
          }}
          onClick={handleClick}
        >
          <Add />
          <span>Δημιουργία Λίστας Ελέγχου</span>
        </button>
      </div>
    </>
  );
};
export default AddChecklistTemplateButton;
