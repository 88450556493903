import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Button,
  Box,
  Pagination,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  Avatar,
} from "@mui/material";
import {
  RemoveRedEye,
  Edit,
  Search,
  Clear,
  Delete,
  Add,
} from "@mui/icons-material/";

import { Loader, PopUpWindow, AddReservationForm, ChecklistForm } from "..";
import io from "socket.io-client";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const socket = io("https://www.maliarakis-server.rhodesislandpass.com/");

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const AssignedToCell = ({ user }) => {
  // Placeholder for fetching user names based on IDs
  // In a real application, replace this with actual logic to fetch user details

  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      <Tooltip key={user._id} title={user.name}>
        <Avatar style={{ backgroundColor: getColor(user.name) }}>
          {user.name[0]}
        </Avatar>
      </Tooltip>
    </Box>
  );
};
function ChecklistTable() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [checklists, setChecklists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalChecklists, setTotalChecklists] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [sortField, setSortField] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState(-1); // -1 for descending, 1 for ascending

  const [deletePopUpWindowIsOpen, setDeletePopUpWindowIsOpen] = useState(false);
  const [editPopUpWindowIsOpen, setEditPopUpWindowIsOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);

  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);

  useEffect(() => {
    const handleReservationCreate = (reservation) => {
      setTotalChecklists((prevTotalReservations) => {
        const newTotal = prevTotalReservations + 1;

        // Calculate new page count based on the updated total checklists
        setPageCount(Math.ceil(newTotal / itemsPerPage));

        return newTotal;
      });

      if (currentPage === 1) {
        setChecklists((prevReservations) => {
          if (prevReservations.length === itemsPerPage) {
            // If the page is full, remove the last item and add the new reservation at the start
            return [reservation, ...prevReservations.slice(0, -1)];
          } else {
            // If the page is not full, just add the new reservation at the start
            return [reservation, ...prevReservations];
          }
        });
      }
    };

    const handleReservationUpdate = (updatedReservation) => {
      // Update the reservation in the state
      setChecklists((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation._id === updatedReservation._id
            ? updatedReservation
            : reservation
        )
      );
    };

    const handleReservationDelete = (reservationId) => {
      // Remove the deleted reservation from the state
      if (checklists.find((reservation) => reservation._id === reservationId)) {
        setChecklists((prevReservations) =>
          prevReservations.filter(
            (reservation) => reservation._id !== reservationId
          )
        );
        setTotalChecklists(
          (prevTotalReservations) => prevTotalReservations - 1
        );
      }
    };

    socket.on("reservationCreated", handleReservationCreate);
    socket.on("reservationUpdated", handleReservationUpdate);
    socket.on("reservationDeleted", (reservationId) =>
      handleReservationDelete(reservationId)
    );

    return () => {
      socket.off("reservationCreated", handleReservationCreate);
      socket.off("reservationUpdated", handleReservationUpdate);
      socket.off("reservationDeleted", handleReservationDelete);
    };
  }, [checklists]);

  useEffect(() => {
    setIsLoading(true);
    fetchChecklists(searchTerm, checkIn, checkOut);
    setIsLoading(false);
  }, [currentPage, itemsPerPage, sortField, sortOrder]);

  const deleteReservation = async (reservationId) => {
    try {
      const response = await axios.delete(
        `https://www.maliarakis-server.rhodesislandpass.com/reservation/deleteReservation/${reservationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        // Remove the deleted reservation from the state
        setChecklists((prevReservations) =>
          prevReservations.filter(
            (reservation) => reservation.reservationId !== reservationId
          )
        );
        setTotalChecklists(
          (prevTotalReservations) => prevTotalReservations - 1
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChecklists = async (term, checkIn, checkOut) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://www.maliarakis-server.rhodesislandpass.com/checklist/getChecklists?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${term}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setChecklists([...response.data.checklists]);
      setTotalChecklists(response.data.totalItems);
      setPageCount(Math.ceil(response.data.totalItems / itemsPerPage));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload
    fetchChecklists(searchTerm, checkIn, checkOut);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    event.stopPropagation(); // Stop the event from propagating to the form's onSubmit handler
    setSearchTerm("");
    setCheckIn("");
    setCheckOut("");
    setSortField("updatedAt");
    setSortOrder(-1);
    setCurrentPage(1);

    fetchChecklists("", "", "");
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field was clicked
      setSortOrder(sortOrder * -1);
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortOrder(1);
    }
  };

  const findFilled = (checklist) => {
    let filled = 0;
    let total = 0;

    checklist.map((item) => {
      item.items.map((subItem) => {
        if (subItem.value) {
          filled++;
        }
        total++;
      });
    });

    return `${filled}/${total}`;
  };
  return (
    <>
      {isLoading && <Loader />}

      {
        !isLoading && editPopUpWindowIsOpen && (
          <PopUpWindow
            onClose={() => {
              setEditPopUpWindowIsOpen(false);
            }}
            unfocusClose={true}
          >
            <ChecklistForm
              edit={edit}
              view={view}
              selectedChecklist={selectedReservation}
            />
          </PopUpWindow>
        )
      }

      {/* {
        !isLoading && deletePopUpWindowIsOpen && (

          <PopUpWindow
            onClose={() => {
              setDeletePopUpWindowIsOpen(false);
            }}
            unfocusClose={true}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2>Διαγραφή Κράτησης</h2>
              <p>Είστε σίγουροι ότι θέλετε να διαγράψετε την κράτηση;</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setDeletePopUpWindowIsOpen(false);
                  }}
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    color: "var(--primary-text-color)",
                  }}

                >
                  ΑΚΥΡΩΣΗ
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--primary-text-color)",
                  }}

                  onClick={async () => {
                    await deleteReservation(selectedReservation._id);
                    setDeletePopUpWindowIsOpen(false);
                  }}
                >
                  ΔΙΑΓΡΑΦΗ

                </Button>
              </div>
            </div>
          </PopUpWindow>
        )
      } */}

      {!isLoading && (
        <>
          <form
            onSubmit={handleSearchSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "95%",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Αναζήτηση καταλύματος"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <Tooltip title="Clear search">
                        <IconButton
                          onClick={() => {
                            setSearchTerm("");
                            fetchChecklists("", checkIn, checkOut);
                          }}
                          aria-label="clear search"
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  padding: "10px",
                  width: "20%",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--primary-text-color)",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                ΑΝΑΖΗΤΗΣΗ
              </Button>

              <Button
                variant="contained"
                color="secondary"
                style={{
                  marginLeft: "10px",
                  padding: "10px",
                  width: "20%",
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--primary-text-color)",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
                onClick={handleClearSearch}
              >
                ΕΚΚΑΘΑΡΙΣΗ
              </Button>
            </div>
          </form>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 540,
              overflow: "auto",
              width: "95%",
              paddingRight: "20px",
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>

                  <TableCell align="center" key="property">
                    Καταλύμα
                  </TableCell>
                  <TableCell align="center" key="checkIn">
                    Συμπληρωμένα
                  </TableCell>

                  <TableCell align="left" key="phone">
                    Συμπληρώθηκε από
                  </TableCell>
                  <TableCell>
                    Τελευταία Ενημέρωση
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checklists.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {

                            setSelectedReservation(row);
                            setView(true);
                            setEdit(false);
                            setEditPopUpWindowIsOpen(true);
                          }}
                        >
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>
                      {/* <Tooltip title="Edit">
                        <Button
                          onClick={() => {
                            setSelectedReservation(row);
                            setEdit(true);
                            setView(false);
                            setEditPopUpWindowIsOpen(true);
                          }}
                        >
                          <Edit sx={{ color: "var(--primary-color)" }} />
                        </Button>
                      </Tooltip> */}
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.actionId? row.actionId.property.name : "Διαγράφηκε η δραστηριότητα"}
                    </TableCell>
                    <TableCell align="center">
                      {findFilled(row.items)}
                    </TableCell>


                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",

                          gap: "4px",
                          alignItems: "center",
                          maxWidth: "150px",
                          overflowX: "auto",
                        }}
                      >
                        {row.updated_by.map((user) => {
                          return <AssignedToCell key={user._id} user={user} />;
                        })}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {formatDate(row.updated_at)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
            sx={{ width: "95%" }}
          >
            <Typography>
              Showing{" "}
              {Math.min((currentPage - 1) * itemsPerPage + 1, totalChecklists)}-
              {Math.min(currentPage * itemsPerPage, totalChecklists)} of{" "}
              {totalChecklists} items
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
              }}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
}

export default ChecklistTable;
