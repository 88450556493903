import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Box,
  Tooltip,
  Chip,
  TableSortLabel,
  Button,
  Pagination,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import Select from "react-select";
import {
  RemoveRedEye,
  Edit,
  Search,
  Clear,
  Delete,
  Archive,
  Restore,
} from "@mui/icons-material/";
import {
  Loader,
  PopUpWindow,
  ViewTicketForm,
  EditTicketForm,
  DeleteTicket,
} from "../../components";
import io from "socket.io-client";
import { json } from "react-router-dom";
import axios from "axios";

// Function to generate a color based on the username
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const findDueDateColor = (dueDate) => {
  const date = new Date(dueDate);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#FF0000";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

const stringOutArray = (array) => {
  let string = "";
  if (array === null || array === undefined || array === "") {
    return string;
  }
  array.forEach((element) => {
    string += element.value._id + ",";
  });
  return string.slice(0, -1);
};

const AssignedToCell = ({ userIds, allUsers }) => {
  // Placeholder for fetching user names based on IDs
  // In a real application, replace this with actual logic to fetch user details
  const users = userIds.map(
    (userId) =>
      allUsers.find((user) => user._id === userId) || { name: "Unknown" }
  );

  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      {users.map((user) => (
        <Tooltip key={user._id} title={user.name}>
          <Avatar style={{ backgroundColor: getColor(user.name) }}>
            {user.name[0]}
          </Avatar>
        </Tooltip>
      ))}
    </Box>
  );
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const socket = io("https://www.maliarakis-server.rhodesislandpass.com/");

const TicketTable = (
  props = {
    searchUsers: [],
    searchStatus: "",
    searchPriority: "",
    searchCategory: "",
    searchProperty: "",
    mini: false,
  }
) => {
  const [deleteTicketOpen, setDeleteTicketOpen] = useState(false);
  const [ticketIsOpen, setTicketisOpen] = useState(false);
  const [editTicketOpen, setEditTicketOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [openTicket, setOpenTicket] = useState();
  const [tickets, setTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage, setTicketsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("updated_at");
  const [sortOrder, setSortOrder] = useState(-1); // -1 for descending, 1 for ascending
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchUsers, setSearchUsers] = useState(
    props.searchUsers ? props.searchUsers : []
  );
  const [searchStatus, setSearchStatus] = useState(
    props.searchStatus ? props.searchStatus : ""
  );
  const [searchPriority, setSearchPriority] = useState(
    props.searchPriority ? props.searchPriority : ""
  );
  const [searchCategory, setSearchCategory] = useState(
    props.searchCategory ? props.searchCategory : ""
  );
  const [searchProperty, setSearchProperty] = useState(
    props.searchProperty ? props.searchProperty : ""
  );

  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  const urlIncludesArchived = window.location.href.includes("archived");

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://www.maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/ticket-options/getStatusOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setStatusOptions(response.data.statusOptions);
    } catch (error) {
      console.error("Failed to fetch status options: ", error);
    }
  };

  const fetchPriorityOptions = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/ticket-options/getPriorityOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setPriorityOptions(response.data.priorityOptions);
    } catch (error) {
      console.error("Failed to fetch priority options: ", error);
    }
  };

  const fetchCategoryOptions = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/ticket-options/getCategoryOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCategoryOptions(response.data.categoryOptions);
    } catch (error) {
      console.error("Failed to fetch category options: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/property/getPropertiesNames",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchTickets = async (
    term = "",
    checkIn = "",
    checkOut = "",
    status = "",
    priority = "",
    category = "",
    property = "",
    users = ""
  ) => {
    try {
      const response = await fetch(
        `https://www.maliarakis-server.rhodesislandpass.com/ticket/getTickets?search=${term}&checkIn=${checkIn}&checkOut=${checkOut}&status=${stringOutArray(
          status
        )}&priority=${stringOutArray(priority)}&category=${stringOutArray(
          category
        )}&property=${stringOutArray(property)}&users=${stringOutArray(
          users
        )}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }&page=${currentPage}&limit=${ticketsPerPage}&createdByUser=${
          props.createdBy
        }&archived=${props.archived ? true : false}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const data = await response.json();
      setTickets(data.tickets);
      setTotalTickets(data.totalItems);
      setPageCount(Math.ceil(data.totalItems / ticketsPerPage));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenTicket = (ticket) => {
    setOpenTicket(ticket);
    setTicketisOpen(true);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload
    fetchTickets(
      searchTerm,
      checkIn,
      checkOut,
      searchStatus,
      searchPriority,
      searchCategory,
      searchProperty,
      searchUsers
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    event.stopPropagation(); // Stop the event from propagating to the form's onSubmit handler
    setSearchTerm("");
    setCheckIn("");
    setCheckOut("");
    setSortField("updated_at");
    setSortOrder(-1);
    setCurrentPage(1);
    setSearchStatus("");
    setSearchPriority("");
    setSearchCategory("");
    setSearchProperty("");
    if (user.permissions.canViewAllTickets) {
      setSearchUsers("");
      fetchTickets();
    } else {
      fetchTickets("", "", "", "", "", "", "", searchUsers);
    }
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field was clicked
      setSortOrder(sortOrder * -1);
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortOrder(1);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchTickets(
      searchTerm,
      checkIn,
      checkOut,
      searchStatus,
      searchPriority,
      searchCategory,
      searchProperty,
      searchUsers
    );
    setIsLoading(false);
  }, [currentPage, sortField, sortOrder]);

  useEffect(() => {
    const handleNewTicket = (newTicket) => {
      if (
        (searchStatus.includes(newTicket.status) || searchStatus === "") &&
        (searchPriority.includes(newTicket.priority) ||
          searchPriority === "") &&
        (searchCategory.includes(newTicket.type) || searchCategory === "") &&
        (searchProperty.includes(newTicket.property) ||
          searchProperty === "") &&
          (searchUsers.length === 0 ||
            searchUsers
              .map((user) => user.value._id)
              .filter((userId) => newTicket.assignedTo.includes(userId))
              .length > 0) &&
          ((props.createdBy === true && newTicket.createdBy === user._id)|| props.createdBy === false)&&
        !newTicket.archived
      ) {
        setTotalTickets((prevTotalTickets) => {
          const newTotalTickets = prevTotalTickets + 1;
          setPageCount(Math.ceil(newTotalTickets / ticketsPerPage));
          return newTotalTickets;
        });

        if (currentPage === 1) {
          setTickets((prevTickets) => {
            if (prevTickets.length === ticketsPerPage) {
              return [newTicket, ...prevTickets.slice(0, -1)];
            } else {
              return [newTicket, ...prevTickets];
            }
          });
        }
      }
    };

    const handleUpdatedTicket = async (updatedTicket) => {
      if (updatedTicket.archived && urlIncludesArchived) {
        setTickets((prevTickets) =>
          prevTickets.filter((ticket) => ticket._id !== updatedTicket._id)
        );
        setTotalTickets((prevTotalTickets) => {
          const newTotalTickets = prevTotalTickets - 1;
          setPageCount(Math.ceil(newTotalTickets / ticketsPerPage));
          return newTotalTickets;
        });

        return;
      }

      if (updatedTicket.archived) {
        setTickets((prevTickets) =>
          prevTickets.filter((ticket) => ticket._id !== updatedTicket._id)
        );
        setTotalTickets((prevTotalTickets) => {
          const newTotalTickets = prevTotalTickets - 1;
          setPageCount(Math.ceil(newTotalTickets / ticketsPerPage));
          return newTotalTickets;
        });
        return;
      }

      if(
        tickets.find((ticket) => ticket._id === updatedTicket._id)
        && !(
          (searchStatus.includes(updatedTicket.status) || searchStatus === "") &&
          (searchPriority.includes(updatedTicket.priority) ||
            searchPriority === "") &&
          (searchCategory.includes(updatedTicket.type) ||
            searchCategory === "") &&
          (searchProperty.includes(updatedTicket.property) ||
            searchProperty === "") &&
          (searchUsers.length === 0 ||
            searchUsers
              .map((user) => user.value._id)
              .filter((userId) => updatedTicket.assignedTo.includes(userId))
              .length > 0) &&
          (props.createdBy === true || updatedTicket.createdBy === user._id)
        )
      ) {
        setTickets((prevTickets) =>
          prevTickets.filter((ticket) => ticket._id !== updatedTicket._id)
        );
        setTotalTickets((prevTotalTickets) => {
          const newTotalTickets = prevTotalTickets - 1;
          setPageCount(Math.ceil(newTotalTickets / ticketsPerPage));
          return newTotalTickets;
        });
        return;
      }

      if (
        (searchStatus.includes(updatedTicket.status) || searchStatus === "") &&
        (searchPriority.includes(updatedTicket.priority) ||
          searchPriority === "") &&
        (searchCategory.includes(updatedTicket.type) ||
          searchCategory === "") &&
        (searchProperty.includes(updatedTicket.property) ||
          searchProperty === "") &&
        (searchUsers.length === 0 ||
          searchUsers
            .map((user) => user.value._id)
            .filter((userId) => updatedTicket.assignedTo.includes(userId))
            .length > 0) &&
        ((props.createdBy === true && updatedTicket.createdBy === user._id)|| props.createdBy === false)
      ) {

        console.log("tickets: ", tickets)
        console.log("updatedTicket: ", updatedTicket)

        console.log("tickets.filter((ticket) => ticket._id === updatedTicket._id):",
          tickets.filter((ticket) => ticket._id === updatedTicket._id).length
        )
        if (!tickets.find((ticket) => ticket._id === updatedTicket._id)) {
          setTickets((prevTickets) => [ ...prevTickets, updatedTicket]);
          setTotalTickets((prevTotalTickets) => {
            const newTotalTickets = prevTotalTickets + 1;
            setPageCount(Math.ceil(newTotalTickets / ticketsPerPage));
            return newTotalTickets;
          });
          return;
        }
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket._id === updatedTicket._id ? updatedTicket : ticket
          )
        );

        return;
      }

      // if ( await openTicket?._id === updatedTicket._id) {
      //   setOpenTicket(updatedTicket);
      // }
    };

    const handleDeletedTicket = (deletedTicketId) => {
      setTickets((prevTickets) =>
        prevTickets.filter((ticket) => ticket._id !== deletedTicketId)
      );

      setTotalTickets((prevTotalTickets) => {
        const newTotalTickets = prevTotalTickets - 1;
        setPageCount(Math.ceil(newTotalTickets / ticketsPerPage));
        return newTotalTickets;
      });
    };

    socket.on("ticketCreated", handleNewTicket);
    socket.on("ticketUpdated", handleUpdatedTicket);
    socket.on("ticketDeleted", (deletedTicketId) => {
      handleDeletedTicket(deletedTicketId);
    });

    return () => {
      socket.off("ticketCreated", handleNewTicket);
      socket.off("ticketUpdated", handleUpdatedTicket);
      socket.off("ticketDeleted", handleDeletedTicket);
    };
  }, [tickets]);

  useEffect(() => {
    fetchTickets(
      searchTerm,
      checkIn,
      checkOut,
      searchStatus,
      searchPriority,
      searchCategory,
      searchProperty,
      searchUsers
    );
    fetchStatusOptions();
    fetchPriorityOptions();
    fetchCategoryOptions();
    fetchProperties();
    fetchUsers();
    fetchGroups();
  }, []);

  return (
    <>
      {!isLoading && ticketIsOpen && (
        <PopUpWindow
          onClose={() => {
            setTicketisOpen(false);
          }}
          color={"#484C4F"}
          width={"65%"}
        >
          <ViewTicketForm
            ticket={openTicket}
            users={users}
            groups={groups}
            properties={properties}
            statuses={statusOptions}
            priorities={priorityOptions}
            categories={categoryOptions}
          />
        </PopUpWindow>
      )}
      {!isLoading && editTicketOpen && (
        <PopUpWindow
          onClose={() => {
            setEditTicketOpen(false);
          }}
          width={"65%"}
        >
          <EditTicketForm
            ticket={openTicket}
            users={users}
            groups={groups}
            properties={properties}
            statuses={statusOptions}
            priorities={priorityOptions}
            categories={categoryOptions}
          />
        </PopUpWindow>
      )}

      {!isLoading && deleteTicketOpen && (
        <PopUpWindow
          onClose={() => {
            setDeleteTicketOpen(false);
          }}
          width={"65%"}
        >
          <DeleteTicket ticket={openTicket} />
        </PopUpWindow>
      )}
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          {!props.mini && (
            <form
              onSubmit={handleSearchSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "95%",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  variant="outlined"
                  placeholder="Αναζήτηση κράτησης, τίτλου ή περιγραφής"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <Tooltip title="Εκαθάριση">
                          Δημιουργία
                          <IconButton
                            onClick={() => {
                              setSearchTerm("");
                              fetchTickets("");
                            }}
                            aria-label="Εκαθάριση"
                          >
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  label="Check-In"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={checkIn}
                  onChange={(e) => setCheckIn(e.target.value)}
                  sx={{ marginLeft: "10px", width: "20%" }}
                />
                <TextField
                  label="Check-Out"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={checkOut}
                  onChange={(e) => setCheckOut(e.target.value)}
                  sx={{ marginLeft: "10px", width: "20%" }}
                />
              </div>

              <div
                className="form-group"
                style={{
                  zIndex: 1001,
                }}
              >
                <div className="form-field">
                  <label htmlFor="type" className="form-field-label">
                    Τύπος:
                  </label>
                  <Select
                    id="type"
                    name="type"
                    value={searchCategory}
                    onChange={(selectedOption) =>
                      setSearchCategory(selectedOption)
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--primary-color)",
                        primary25: "var(--secondary-color)",
                      },
                    })}
                    options={
                      categoryOptions &&
                      categoryOptions.map((category) => ({
                        value: category,
                        label: category.title,
                      }))
                    } // Add more options as needed
                    isClearable
                    isMulti
                  />
                </div>{" "}
                <div className="form-field">
                  <label htmlFor="priority" className="form-field-label">
                    Προτεραιότητα:
                  </label>
                  <Select
                    id="priority"
                    name="priority"
                    value={searchPriority}
                    onChange={(selectedOption) =>
                      setSearchPriority(selectedOption)
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--primary-color)",
                        primary25: "var(--secondary-color)",
                      },
                    })}
                    options={
                      priorityOptions &&
                      priorityOptions.map((priority) => ({
                        value: priority,
                        label: priority.title,
                      }))
                    } // Add more options as needed
                    isClearable
                    isMulti
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="status" className="form-field-label">
                    Κατάσταση:
                  </label>

                  <Select
                    id="status"
                    name="status"
                    value={searchStatus}
                    onChange={(selectedOption) =>
                      setSearchStatus(selectedOption)
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--primary-color)",
                        primary25: "var(--secondary-color)",
                      },
                    })}
                    options={
                      statusOptions &&
                      statusOptions.map((status) => ({
                        value: status,
                        label: status.title,
                      }))
                    } // Add more options as needed
                    isClearable
                    isMulti
                  />
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  zIndex: 1000,
                }}
              >
                <div className="form-field">
                  <label htmlFor="property" className="form-field-label">
                    Κατάλυμα:
                  </label>
                  <Select
                    id="property"
                    name="property"
                    value={searchProperty}
                    onChange={(selectedOption) =>
                      setSearchProperty(selectedOption)
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--primary-color)",
                        primary25: "var(--secondary-color)",
                      },
                    })}
                    options={
                      properties &&
                      properties.map((property) => ({
                        value: property,
                        label: property.name,
                      }))
                    } // Add more options as needed
                    isClearable
                    isMulti
                  />
                </div>
                <div
                  className="form-field"
                  style={{
                    visibility: user.permissions.canViewAllTickets
                      ? "visible"
                      : "hidden",
                    width: user.permissions.canViewAllTickets ? "100%" : "0%",
                  }}
                >
                  <label htmlFor="assignedTo" className="form-field-label">
                    Ανατεθειμένο σε:
                  </label>
                  <Select
                    id="assignedTo"
                    name="assignedTo"
                    value={searchUsers}
                    onChange={(selectedOption) =>
                      setSearchUsers(selectedOption)
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--primary-color)",
                        primary25: "var(--secondary-color)",
                      },
                      zIndex: 1000,
                    })}
                    options={
                      users &&
                      users.map((user) => ({
                        value: user,
                        label: user.username,
                      }))
                    } // Add more options as needed
                    isMulti
                    isClearable
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "10px",
                    width: "20%",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--primary-text-color)",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  onClick={handleSearchSubmit}
                >
                  ΑΝΑΖΗΤΗΣΗ
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    marginLeft: "10px",
                    padding: "10px",
                    width: "20%",
                    backgroundColor: "var(--secondary-color)",
                    color: "var(--primary-text-color)",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  onClick={handleClearSearch}
                >
                  ΕΚΑΘΑΡΙΣΗ
                </Button>
              </div>
            </form>
          )}
          <TableContainer
            component={Paper}
            sx={{
              maxheight: 540,
              overflow: "auto",
              overflowX: "scroll",
              width: "100%",
              paddingRight: "20px",
            }}
          >
            <Table
              aria-label="simple table"
              stickyHeader
              sx={{
                padding: "10px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "title"}
                      direction={
                        sortField === "title" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("title")}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Τίτλος
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "description"}
                      direction={
                        sortField === "description" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("description")}
                    >
                      Περιγραφή
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "property"}
                      direction={
                        sortField === "property" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("property")}
                    >
                      Κατάλυμα
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "dueDate"}
                      direction={
                        sortField === "dueDate" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("dueDate")}
                    >
                      Ημ. Λήξης
                    </TableSortLabel>
                  </TableCell>

                  {!props.mini && (
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortField === "type"}
                        direction={
                          sortField === "type" && sortOrder === 1
                            ? "asc"
                            : "desc"
                        }
                        onClick={() => handleSortChange("type")}
                      >
                        Τύπος
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {!props.mini && (
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortField === "priority"}
                        direction={
                          sortField === "priority" && sortOrder === 1
                            ? "asc"
                            : "desc"
                        }
                        onClick={() => handleSortChange("priority")}
                      >
                        Προτεραιότητα
                      </TableSortLabel>
                    </TableCell>
                  )}

                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "status"}
                      direction={
                        sortField === "status" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("status")}
                    >
                      Κατάσταση
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="center">
                    <TableSortLabel
                      active={sortField === "assignedTo"}
                      direction={
                        sortField === "assignedTo" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("assignedTo")}
                    >
                      Ανατεθειμένο σε
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.map((ticket, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    maxheight="100px"
                  >
                    <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="Προβολή" placement="right">
                        <Button
                          onClick={() => {
                            handleOpenTicket(ticket);
                          }}
                        >
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>
                      {user.permissions.canEditTicket && (
                        <Tooltip title="Επεξεργασία" placement="right">
                          <Button
                            onClick={() => {
                              setEditTicketOpen(true);
                              setOpenTicket(ticket);
                            }}
                          >
                            <Edit sx={{ color: "var(--primary-color)" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="left">{ticket.title}</TableCell>
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip
                        title={ticket.description}
                        PopperProps={{
                          style: { zIndex: 10001 },
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "13px",
                            },
                          },
                        }}
                      >
                        <span>
                          {ticket.description.substring(0, 50) +
                            (ticket.description.length > 50 ? "..." : "")}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      {
                        properties.find(
                          (property) => property._id === ticket.property
                        )?.name
                      }
                    </TableCell>
                    <TableCell align="left">
                      {ticket.dueDate ? (
                        <Chip
                          label={formatDate(ticket.dueDate)}
                          style={{
                            backgroundColor: findDueDateColor(ticket.dueDate),
                            color: findContrastColor(
                              findDueDateColor(ticket.dueDate)
                            ),
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    {!props.mini && (
                      <TableCell align="left">
                        {categoryOptions.map((category) => {
                          if (category._id === ticket.type) {
                            return (
                              <Chip
                                label={category.title}
                                style={{
                                  alignSelf: "left",
                                  backgroundColor: category.color,
                                  color: findContrastColor(category.color),
                                }}
                              />
                            );
                          }
                          return null; // Make sure to return null or equivalent when condition doesn't match
                        })}
                      </TableCell>
                    )}
                    {!props.mini && (
                      <TableCell align="left">
                        {priorityOptions.map((priority) => {
                          if (priority._id === ticket.priority) {
                            return (
                              <Chip
                                label={priority.title}
                                style={{
                                  backgroundColor: priority.color,
                                  color: findContrastColor(priority.color),
                                }}
                              />
                            );
                          }
                          return null;
                        })}
                      </TableCell>
                    )}

                    <TableCell align="left">
                      {statusOptions.map((status) => {
                        if (status._id === ticket.status) {
                          return (
                            <Chip
                              label={status.title}
                              style={{
                                backgroundColor: status.color,
                                color: findContrastColor(status.color),
                              }}
                            />
                          );
                        }
                        return null;
                      })}
                    </TableCell>

                    <TableCell align="left">
                      <AssignedToCell
                        userIds={ticket.assignedTo}
                        allUsers={users ? users : null}
                      />
                    </TableCell>

                    <TableCell align="left">
                      {urlIncludesArchived &&
                        user.permissions.canRestoreTickets && (
                          <Tooltip title="Restore" placement="right">
                            <Button
                              onClick={() => {
                                axios
                                  .put(
                                    `https://www.maliarakis-server.rhodesislandpass.com/ticket/updateTicket/${ticket._id}`,
                                    {
                                      archived: false,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${user.token}`,
                                      },
                                    }
                                  )
                                  .catch((error) => {
                                    console.error(
                                      "Failed to restore ticket: ",
                                      error
                                    );
                                  });
                              }}
                            >
                              <Restore sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        )}

                      {!urlIncludesArchived &&
                        user.permissions.canArchiveTickets && (
                          <Tooltip title="Αρχειοθέτηση" placement="right">
                            <Button
                              onClick={() => {
                                axios
                                  .put(
                                    `https://www.maliarakis-server.rhodesislandpass.com/ticket/updateTicket/${ticket._id}`,
                                    {
                                      archived: true,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${user.token}`,
                                      },
                                    }
                                  )

                                  .catch((error) => {
                                    console.error(
                                      "Failed to archive ticket: ",
                                      error
                                    );
                                  });
                              }}
                            >
                              <Archive sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        )}

                      {user.permissions.canDeleteTicket && (
                        <Tooltip title="Διαγραφή" placement="right">
                          <Button
                            onClick={() => {
                              setDeleteTicketOpen(true);
                              setOpenTicket(ticket);
                            }}
                          >
                            <Delete sx={{ color: "var(--primary-color)" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!props.mini && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={2}
              sx={{ width: "95%" }}
            >
              <Typography>
                Showing{" "}
                {Math.min((currentPage - 1) * ticketsPerPage + 1, totalTickets)}
                -{Math.min(currentPage * ticketsPerPage, totalTickets)} of{" "}
                {totalTickets} items
              </Typography>
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={(event, page) => {
                  setCurrentPage(page);
                }}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default TicketTable;
