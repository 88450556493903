import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Reservations.css";

import {
  ImportReservationButton,
  Sidebar,
  TaskManager,
  ReservationTable,
  Loader,
  AddReservationButton,
} from "../../components";
import { Add, Task } from "@mui/icons-material";
import { PageStructure } from "../../pages";

const Reservations = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PageStructure>
      <h1>Κρατήσεις</h1>

      {isLoading && <Loader />}
      <div
        style={
          {
            // visibility: isLoading ? "hidden" : "visible",
          }
        }
      >

        <div
        style={
          {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            overflowX: "auto",

          }
        }
        >

        <ImportReservationButton setIsLoading={setIsLoading} />
        <AddReservationButton />
        </div>

        <ReservationTable />
      </div>
    </PageStructure>
  );
};

export default Reservations;
