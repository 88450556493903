import React, { useState, useEffect } from "react";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Update, Delete } from "@mui/icons-material";
import Select, { components } from "react-select";

import axios from "axios";

const CustomOption = (props) => {
  return (
    <Tooltip
      title="color"
      placement="left"
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
            backgroundColor: `${props.data.tooltip}`,
            color: ` ${props.data.tooltip}`,
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function formatDateTime(date) {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().slice(0, 16);
}
const AddActionForm = (props) => {
  const [actionData, setActionData] = useState({
    action_option: props.action_option || "",
    property: props.property || "",
    group: props.group || "",
    assigned_to: props.assigned_to || [],
    planned_start_date: props.date ? formatDateTime(props.date) : "",
    price: props.price || "",
    notes: props.notes || "",
  });

  const actionId = props.actionId || "";

  const edit = props.edit || false;
  const view = props.view || false;

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const [usersOptions, setUsersOptions] = useState([]); // Add this line

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);
  const [properties, setProperties] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://www.maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
      setUsersOptions(
        response.data.users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/property/getPropertiesNamesAndPrices",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchActionOptions = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActionOptions(response.data.actionOptions);
    } catch (error) {
      console.error("Failed to fetch action options: ", error);
    }
  };

  const handleGroupChange = (selectedGroups) => {
    const newUsers = selectedGroups.users.filter(
      (user) =>
        !actionData.assigned_to.map((user) => user.value._id).includes(user._id)
    );

    setActionData({
      ...actionData,
      group: selectedGroups,
      assigned_to: actionData.assigned_to.concat(
        newUsers.map((user) => ({
          value: {
            _id: user._id,
            username: user.username,
          },
          label: user.username,
        }))
      ),
    });

    console.log({
      value: selectedGroups.value,
      label: selectedGroups.label,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setActionData({
      ...actionData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    if (!view) {
      e.preventDefault();
      setIsLoading(true);

      const dataToSend = {
        ...actionData,
        action_option: actionData.action_option?.value?._id,
        property: actionData.property?.value?._id,
        group: actionData.group? actionData.group.value._id : "",
        assigned_to: actionData.assigned_to.map((user) => user.value._id),
        planned_start_date: formatDateData(actionData.planned_start_date),
        price: actionData.price,
        notes: actionData.notes,
      };

      try {
        if (!edit) {
          const response = await axios.post(
            "https://www.maliarakis-server.rhodesislandpass.com/action/createAction",
            {
              action_option: dataToSend.action_option,
              property: dataToSend.property,
              groupAssignedTo: JSON.stringify(dataToSend.group),
              assignedTo: JSON.stringify(dataToSend.assigned_to),
              planned_start_date: dataToSend.planned_start_date,
              price: dataToSend.price,
              notes: dataToSend.notes,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
        } else {
          const response = await axios.put(
            `https://www.maliarakis-server.rhodesislandpass.com/action/updateAction/${actionId}`,
            {
              action_option: dataToSend.action_option,
              property: dataToSend.property,
              groupAssignedTo: JSON.stringify(dataToSend.group),
              assignedTo: JSON.stringify(dataToSend.assigned_to),
              planned_start_date: dataToSend.planned_start_date,
              price: dataToSend.price,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
        }

        setSuccessMessage(
          !edit
            ? "Η δραστηριότητα δημιουργήθηκε επιτυχώς"
            : "Η δραστηριότητα ενημερώθηκε επιτυχώς"
        );
        setErrorMessage("");
        setIsLoading(false);
        window.location.reload();
      } catch (error) {
        setSuccessMessage("");
        setErrorMessage(
          !edit
            ? "Σφάλμα κατά τη δημιουργία της δραστηριότητας"
            : "Σφάλμα κατά την ενημέρωση της δραστηριότητας"
        );
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchProperties();
    fetchActionOptions();
  }, []);

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={view ? null : handleSubmit}>
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="action_option">
                Ενέργεια
              </label>
              <Select
                isDisabled={view}
                options={actionOptions
                  .filter((option) => {
                    return !actionData.action_option
                      ? true
                      : option._id !== actionData.action_option.value._id;
                  })
                  .map((option) => ({
                    value: option,
                    label: option.name,
                  }))}
                onChange={(selectedOption) => {
                  setActionData({
                    ...actionData,
                    action_option: selectedOption,
                  });

                  if (actionData.property) {
                    console.log(actionData.property);
                    setActionData({
                      ...actionData,
                      action_option: selectedOption,
                      price: properties
                        .find(
                          (property) =>
                            property._id === actionData.property.value._id
                        )
                        .actions_prices.find(
                          (price) => price._id === selectedOption.value._id
                        )
                        ? properties
                            .find(
                              (property) =>
                                property._id === actionData.property.value._id
                            )
                            .actions_prices.find(
                              (price) => price._id === selectedOption.value._id
                            ).price
                        : actionData.price,
                    });
                  }
                }}
                value={actionData.action_option}
                placeholder="Επιλέξτε ενέργεια"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="property">
                Κατάλυμα
              </label>
              <Select
                isDisabled={view}
                
                options={properties
                  .filter((property) => {
                    return !actionData.property
                      ? true
                      : property._id !== actionData.property.value._id;
                  })
                  .map((property) => ({
                    value: property,
                    label: property.name,
                  }))}
                onChange={(selectedOption) => {
                  setActionData({
                    ...actionData,
                    property: selectedOption,
                  });

                  if (actionData.action_option) {
                    setActionData({
                      ...actionData,
                      property: selectedOption,

                      price: selectedOption.value.actions_prices.find(
                        (price) =>
                          price._id === actionData.action_option.value._id
                      )
                        ? selectedOption.value.actions_prices.find(
                            (price) =>
                              price._id === actionData.action_option.value._id
                          ).price
                        : actionData.price,
                    });
                  }
                }}
                value={actionData.property}
                placeholder="Επιλέξτε κατάλυμα"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="group">
                Ομάδα
              </label>
              <Select
                isDisabled={view}
                options={groups
                  .filter((group) => {
                    return !actionData.group
                      ? true
                      : group._id !== actionData.group.value._id;
                  })
                  .map((group) => ({
                    value: group,
                    label: group.name,
                    users: group.users,
                  }))}
                onChange={(selectedOption) => {
                  handleGroupChange(selectedOption);
                  // setActionData({
                  //   ...actionData,
                  //   group: selectedOption,
                  // });
                }}
                value={actionData.group}
                placeholder="Επιλέξτε ομάδα"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="assigned_to">
                Ανατεθειμένοι
              </label>
              <Select
                isDisabled={view}
                options={users
                  .filter((user) => {
                    return !actionData.assigned_to
                      ? true
                      : !actionData.assigned_to
                          .map((user) => user.value._id)
                          .includes(user._id);
                  })
                  .map((user) => ({
                    value: user,
                    label: user.username,
                    tooltip: user.name,
                  }))}
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  setUsersOptions(
                    users
                      .filter(
                        (user) =>
                          !selectedOption
                            .map((option) => option.value._id)
                            .includes(user._id)
                      )
                      .map((user) => ({
                        value: user,
                        label: user.username,
                        tooltip: user.name,
                      }))
                  );
                  setActionData({
                    ...actionData,
                    assigned_to: selectedOption,
                  });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                value={actionData.assigned_to}
                placeholder="Επιλέξτε χρήστες"
                isMulti
              />
            </div>
          </div>
{
  user.permissions.canViewPrices &&
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="price">
                Τιμή
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="price"
                value={actionData.price}
                onChange={handleInputChange}
              />
            </div>
          </div>}

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="planned_start_date">
                Ημερομηνία
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="datetime-local"
                name="planned_start_date"
                value={actionData.planned_start_date}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="notes">
                Σημειώσεις
              </label>
              <textarea
                disabled={view}
                className="form-field-input"
                name="notes"
                value={actionData.notes}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div
            className="form-group"
            style={{
              display: view ? "none" : "block",
            }}
          >
            <div className="form-field">
              <button
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
                type="submit"
              >
                {edit ? "Ενημέρωση" : "Δημιουργία"}
              </button>
            </div>
          </div>
        </form>
      )}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
};

export default AddActionForm;
