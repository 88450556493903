import React, { useState, useEffect } from "react";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Update, Delete } from "@mui/icons-material";
import Select, { components } from "react-select";

import axios from "axios";

const CustomOption = (props) => {
  return (
    <Tooltip
      title="color"
      placement="left"
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
            backgroundColor: `${props.data.tooltip}`,
            color: ` ${props.data.tooltip}`,
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const AddReservationForm = (props) => {
  const [actionData, setActionData] = useState({
    reservation_id: props.reservation_id || "",
    property: {
      value: props.property || "",
      label: props.property || "",
    },

    nights: props.nights || 0,
    adults: props.adults || 0,
    kids: props.children || 0,
    checkin: props.checkIn
      ? new Date(props.checkIn)
          .toISOString()
          .slice(0, 16)
          .replace("T", " ")
          .replace("Z", "")
      : formatDateData(
          new Date()
            .toISOString()
            .slice(0, 16)
            .replace("T", " ")
            .replace("Z", "")
        ),
    checkout: props.checkOut
      ? new Date(props.checkOut)
          .toISOString()
          .slice(0, 16)
          .replace("T", " ")
          .replace("Z", "")
      : "",
    staffNotes: props.staffNotes || "",
    firstName: props.firstName || "",
    lastName: props.lastName || "",
    email: props.email || "",
    phone: props.phone || "",
  });
  const _id = props._id || "";

  const edit = props.edit || false;
  const view = props.view || false;

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [properties, setProperties] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://www.maliarakis-server.rhodesislandpass.com/property/getPropertiesNames",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setProperties(
        response.data.propertiesNames.map((property) => property.name)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setActionData({
      ...actionData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    if (!view) {
      e.preventDefault();
      setIsLoading(true);

      const dataToSend = {
        ...actionData,
        reservationId: actionData.reservation_id,
        property: actionData.property.value,
        nights: actionData.nights,
        adults: actionData.adults,
        kids: actionData.kids,
        checkIn: actionData.checkin,
        checkOut: actionData.checkout,
        staffNotes: actionData.staffNotes,
        firstName: actionData.firstName,
        lastName: actionData.lastName,
        email: actionData.email,
        phone: actionData.phone,
      };

      try {
        if (!edit) {
          const response = await axios.post(
            "https://www.maliarakis-server.rhodesislandpass.com/reservation/createReservation",
            {
              reservationId: dataToSend.reservation_id,
              property: dataToSend.property,
              nights: dataToSend.nights,
              adults: dataToSend.adults,
              kids: dataToSend.kids,
              checkIn: dataToSend.checkin,
              checkOut: dataToSend.checkout,
              staffNotes: dataToSend.staffNotes,
              firstName: dataToSend.firstName,
              lastName: dataToSend.lastName,
              email: dataToSend.email,
              phone: dataToSend.phone,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
        } else {
          const response = await axios.put(
            `https://www.maliarakis-server.rhodesislandpass.com/reservation/updateReservation/${_id}`,
            {
              reservationId: dataToSend.reservation_id,
              property: dataToSend.property,
              nights: dataToSend.nights,
              adults: dataToSend.adults,
              kids: dataToSend.kids,
              checkIn: dataToSend.checkin,
              checkOut: dataToSend.checkout,
              staffNotes: dataToSend.staffNotes,
              firstName: dataToSend.firstName,
              lastName: dataToSend.lastName,
              email: dataToSend.email,
              phone: dataToSend.phone,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
        }

        setSuccessMessage(
          !edit
            ? "Η δραστηριότητα δημιουργήθηκε επιτυχώς"
            : "Η δραστηριότητα ενημερώθηκε επιτυχώς"
        );
        setErrorMessage("");
        setIsLoading(false);
      } catch (error) {
        setSuccessMessage("");
        setErrorMessage(
          !edit
            ? "Σφάλμα κατά τη δημιουργία της δραστηριότητας"
            : "Σφάλμα κατά την ενημέρωση της δραστηριότητας"
        );
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={view ? null : handleSubmit}>
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="action_option">
                Reservation ID
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="reservation_id"
                value={actionData.reservation_id}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="property">
                Καταλύμα
              </label>
              <Select
                isDisabled={view}
                options={properties.map((property) => ({
                  value: property,
                  label: property,
                }))}
                value={actionData.property}
                onChange={(selected) => {
                  console.log(actionData);
                  console.log(selected);
                  setActionData({
                    ...actionData,
                    property: selected,
                  });
                }}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="nights">
                Νύχτες
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="number"
                name="nights"
                value={actionData.nights}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="adults">
                Ενήλικες
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="number"
                name="adults"
                value={actionData.adults}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="kids">
                Παιδιά
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="number"
                name="kids"
                value={actionData.kids}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="checkin">
                Checkin
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="datetime-local"
                name="checkin"
                value={actionData.checkin}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="checkout">
                Checkout
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="datetime-local"
                name="checkout"
                value={actionData.checkout}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="staffNotes">
                Staff Notes
              </label>
              <textarea
                disabled={view}
                className="form-field-input"
                type="text"
                name="staffNotes"
                value={actionData.staffNotes}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="firstName">
                Όνομα
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="firstName"
                value={actionData.firstName}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="lastName">
                Επώνυμο
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="lastName"
                value={actionData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="email">
                Email
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="email"
                value={actionData.email}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="phone">
                Τηλέφωνο
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="phone"
                value={actionData.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div
            className="form-group"
            style={{
              display: view ? "none" : "block",
            }}
          >
            <div className="form-field">
              <button
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
                type="submit"
              >
                {edit ? "Ενημέρωση" : "Δημιουργία"}
              </button>
            </div>
          </div>
        </form>
      )}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
};

export default AddReservationForm;
