import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { Replay } from "@mui/icons-material";
import axios from "axios";

import "./LoginCounter.css";

const convertToTime = (time) => {
  const seconds = Math.floor((time / 1000) % 60);
  const minutes = Math.floor((time / (1000 * 60)) % 60);
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24) ;
  return `${hours<10? `0${hours}`: hours}:${minutes<10? `0${minutes}`: minutes}:${seconds<10? `0${seconds}`: seconds}`;
};

const LoginCounter = () => {
  const [logoutTime, setLogoutTime] = useState(
    localStorage.getItem("logout") || new Date().getTime()
  );
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const user = JSON.parse(localStorage.getItem("user"));


  const reloadJWT = async () => {
    try {
      const response = await axios.post(
        "https://www.maliarakis-server.rhodesislandpass.com/auth/refreshToken",
        {
          userId: user.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("user", JSON.stringify(response.data.user) );
      localStorage.setItem(
        "logout",
        new Date().getTime() + 1000 * 60 * 60 * 24
      );
      setLogoutTime(localStorage.getItem("logout"));
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Update the current time every second
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // This useEffect is just an example if you want to make an API call
  // useEffect(() => {
  //     axios.get("https://jsonplaceholder.typicode.com/posts")
  //         .then((response) => {
  //             console.log(response.data);
  //         })
  //         .catch((error) => {
  //             console.log(error);
  //         });
  // }, []);

  return (
    <Tooltip
      title="Reload Time Session"
      onClick={() => {
        reloadJWT();
      }}
     sx={{ fontSize: 20 }}
    >
      <div className="login-counter"
        onClick={() => {
            reloadJWT();
            }
        }
      >
        <h3>{convertToTime(logoutTime - currentTime)}</h3>

        <Replay />
      </div>
    </Tooltip>
  );
};

export default LoginCounter;
