import React, { useEffect } from "react";
import { PageStructure } from "../../pages";
import { AddActionButton, Calendar as CalendarComponent } from "../../components";

const Calendar = (props) => {
  return (
    <PageStructure>

      <AddActionButton/>
      <CalendarComponent />
    </PageStructure>
  );
};

export default Calendar;
