import React, {useState, useEffect,useRef} from "react";
import {useUser} from "../../contexts/UserContext";

import "./Properties.css";
import { 
    AddPropertyButton,
    PropertiesTable
} from "../../components";
import{PageStructure} from "../../pages";
import { Add, Task } from "@mui/icons-material";

const Properties = () => {

        return (
            <PageStructure>

                <h2>Properties</h2>
             
                <AddPropertyButton />
                <PropertiesTable />

            </PageStructure>
        );
    }

export default Properties;