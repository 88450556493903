import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import { Edit } from "@mui/icons-material";

import axios from "axios";

const EditPropertyForm = ({ property, actionOptions }) => {
  const [propertyName, setPropertyName] = useState(property.name);
  const [persons, setPersons] = useState(property.persons);
  const [checkin, setCheckin] = useState(property.checkin);
  const [checkout, setCheckout] = useState(property.checkout);
  const [actionsPrices, setActionsPrices] = useState(property.actions_prices);

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleEditProperty = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (propertyName === "") {
      return setError("Property name is required.");
    }

    // Edit your API call here to edit the property
    try {
      const response = await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/property/updateProperty/${property._id}`,
        {
          title: propertyName,
          persons: persons,
          checkin: checkin,
          checkout: checkout,
          actions_prices: actionsPrices,


        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Κατάλυμα επεξεργάστηκε επιτυχώς.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Κατάλυμα υπάρχει ήδη.");
      } else {
        setError("Αποτυχία επεξεργασίας καταλύματος. Παρακαλώ προσπαθήστε ξανά.");
      }
    }
  };

  return (
    <>
      <div className="ticket-form-wrapper">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}

        <form className="ticket-form" onSubmit={handleEditProperty}>
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="name">
                Όνομα Καταλύματος
              </label>
              <input
                id="name"
                type="text"
                className="form-field-input"
                value={propertyName}
                onChange={(e) => setPropertyName(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="persons">
                Αριθμός Ατόμων
              </label>
              <input
                id="persons"
                type="number"
                className="form-field-input"
                value={persons}
                onChange={(e) => setPersons(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="checkin">
                Checkin
              </label>
              <input
                id="checkin"
                type="text"
                className="form-field-input"
                value={checkin}
                onChange={(e) => setCheckin(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="checkout">
                Checkout
              </label>
              <input
                id="checkout"
                type="text"
                className="form-field-input"
                value={checkout}
                onChange={(e) => setCheckout(e.target.value)}
              />
            </div>
          </div>

          {actionOptions?.map((actionOption, index) => (
            <div className="form-group" key={index}>
              <div className="form-field">
                <label className="form-field-label" htmlFor={`action-${index}`}>
                  {actionOption.name}
                </label>
                <input
                  id={`action-${index}`}
                  type="number"
                  step={0.01}
                  className="form-field-input"
                  value={
                    actionsPrices.find(
                      (actionPrice) => actionPrice._id === actionOption._id
                    )
                      ? actionsPrices.find(
                          (actionPrice) => actionPrice._id === actionOption._id
                        ).price
                      : null
                  }
                  onChange={(e) => {
                    const newActionsPrices = [...actionsPrices];
                    const actionPriceIndex = newActionsPrices.findIndex(
                      (actionPrice) => actionPrice._id === actionOption._id
                    );

                    if (actionPriceIndex === -1) {
                      newActionsPrices.push({
                        _id: actionOption._id,
                        price: e.target.value,
                      });
                    } else {
                      newActionsPrices[actionPriceIndex].price = e.target.value;
                    }

                    setActionsPrices(newActionsPrices);
                  }}
                />
              </div>
            </div>
          ))}

          <div className="form-group">
            <div className="form-field">
              <button type="submit" className="submit-button">
                 
                <Edit />
                <span>Επεξεργασία Καταλύματος</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditPropertyForm;
