import React, { useState, useEffect } from "react";
import { TextField, Alert } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Select from "react-select";
import axios from "axios";

const EditGroupForm = ({ property }) => {
  const [users, setUsers] = useState([]);
  const [groupName, setGroupName] = useState(property.name);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState(property.users);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleEditProperty = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (groupName === "") {
      return setError("Property name is required.");
    }

    // Edit your API call here to edit the property
    try {
      const response = await axios.put(
        `https://www.maliarakis-server.rhodesislandpass.com/group/updateGroup/${property._id}`,
        {
          name: groupName,
          users: selectedUsers.map(user => user._id)
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Property edited successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Property already exists.");
      } else {
        setError("Failed to edit property. Please try again.");
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://www.maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      console.log("users",response.data.users.filter(user => !selectedUsers.find(selectedUser => selectedUser._id === user._id)));
      setUsers(response.data.users.filter(user => !selectedUsers.find(selectedUser => selectedUser._id === user._id)));
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  useEffect(() => {
    console.log("Selected Users",selectedUsers);
    fetchUsers();
  },[]);



  return (
    <>
     { !isLoading && 
     <div className="edit-property-option-wrapper">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <h4
          style={{
            marginBottom: "0px",

            fontWeight: "600",
          }}
        >
          Edit Group
        </h4>
        <form
          onSubmit={handleEditProperty}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            label="Group Name"
            placeholder="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              inputProps: { style: { height: "56px", pediting: "5px" } }, // Adjust the style as needed
            }}
          />

          <Select
            options={users.map((user) => ({
              value: user,
              label: user.username,
            }))}
            isMulti
            placeholder="Select Users"
            value={selectedUsers.map((user) => ({
              value: user,
              label: user.username,
            }))}
            onChange={
              (usersSelected) => {
                console.log("Selected Users",usersSelected);
                console.log("Selected Users",selectedUsers);  
                setSelectedUsers(usersSelected.map(user => user.value));
              }
            }
            styles={{
              control: (styles) => ({
                ...styles,
                width: "100%",
                height: "56px",
                border: "1px solid var(--primary-color)",
                borderRadius: "5px",
              }),
            }}
          />

          <button
            className="add-ticket-button__button"
            sx={{
              backgroundColor: "var(--primary-color)",
              color: "var(--primary-text-color)",
              fontSize: "15px",
              fontWeight: "600",
              width: "auto",
              padding: "10px 20px",
            }}
          >
            <Edit />
            <span>Update</span>
          </button>
        </form>
      </div>}
    </>
  );
};

export default EditGroupForm;
